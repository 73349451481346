.login-container{

   
    background-repeat: no-repeat;
    background-size: cover;
 
    text-shadow: 3px 2px 1px black;


   
    border: 1px solid white;
    background-image: url('./../Home/resources/batman2.JPG');
    background-size: 110%;
 
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    height: 1500px;
    /* position: relative; */
    padding-top: 100px;
    display: flex;
    justify-content: center;
    
    
   
}

.login-container-1{
    width: 100%;
    /* background: blue; */
    background-image: url('./../Home/resources/batman2.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    height: 1500px;
}
.login-form h1 {
    
    font-size: 55px;
    text-shadow: 4px 3px 3px black;
    text-align: center;
    
}

.login-form h2 {
    font-size: 35px;
    text-align: center;
}

.login-form button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: grey;
    margin-bottom: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    /* margin-right: 40px; */
    /* text-shadow: 1px 1px 3px lightgrey; */
}

.login-form button:hover {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}

.login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-size: cover;
    height: 900px;
    background: rgba(100, 100, 100, 0.4);
    color: white;

    width: 100%;
    max-width: 1000px;
    border-radius: 10%;
    padding-right: 150px;
    padding-left: 200px;
    padding-top: 50px;
    padding-bottom: 100px;
    overflow: hidden;
    
    
    
    
  
    border: 1px solid white;
    color: white;
    padding-bottom: 30px;
}

.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;
}
#next{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
}
#next button {
    font-size: 30px;
    width: 250px;
}
#next button:hover {
    font-size: 30px;
}

.label-input {
    /* margin-bottom: px; */
    display: flex;
    /* flex-direction: column; */
    margin-top: 30px;
}

.label-input label {
    margin-bottom: 15px;
    /* align-self: center; */
}

.label-input input {
    /* margin-right: 10px; */
    font-size: 30px;
    margin-bottom: 50px;
    /* border-radius: 1%; */
    font-family: 'Fjalla One', sans-serif;
    /* text-shadow: 1px 1px 1px lightgrey; */
    padding: 5px 5px;
    
    
}

.login-button{
    /* margin-left: 89px; */
}


@media screen and (max-width: 768px) {
    .label-input {
        /* margin-bottom: px; */
        display: flex;
        flex-direction: column;
        margin-top: 45px;
    }

    .form {
        flex-direction: column;
    }

    .login-form{
        border-radius: 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        background-size: cover;
        height: 1500px;
        background: rgba(100, 100, 100, 0.4);
        color: white;
        
    
        width: 100%;
        max-width: 1500px;
        
        
      
        border: 1px solid white;
        color: white;
    }

    .login-form h1 {
        text-align: center;
    }

    .login-container{
        padding-top: 100px;
    }
    
}

@media screen and (max-width: 414px) {
    .login-form{
        width: 100%;
        max-width: 414px;
        height: 100%;
        border-radius: 0%;
        border: none;
        padding: 2px 2px;
        justify-content: flex-start;
        /* align-items: flex-start; */
        

    }

    .login-container-1{
        height:900px;
    }
    .login-container{
        padding-top: 0px;
        height: 800px;
        
        border: none;
    }

    .login-form h1 {
    
        font-size: 30px;
        text-shadow: 2px 2px 2px black;
        text-align: center;
        
    }

    .label-input input {
        /* margin-right: 10px; */
        font-size: 30px;
        margin-bottom: 50px;
        /* border-radius: 1%; */
        font-family: 'Fjalla One', sans-serif;
        /* text-shadow: 1px 1px 1px lightgrey; */
        padding: 10px 5px;
        
        
    }
    form{
        padding-left: 20px;
    }
    
}

