header{  
    height: 150px;
    width: 100%;

    /* max-width: px; */
    /* margin: 0 auto; */
    position: -webkit-sticky;
    position: sticky;
    top: 1px;
    

    
    background: black;
    
 
    /* text-shadow: 4px 3px 3px ligtgrey; */
    color: white;


    /* position: fixed; */
    /* top: 0; */
    /* max-width: 1200px; */
    /* background: black; */
    /* background: black; */
    /* display: flex; */
    /* margin: 0 auto; */


}
.logo-container nav {
    /* width: 1150px; */
}
#next{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    
}

#next-test-nav-down{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}

#next-test-nav-down-register-login{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}
#next button {
    font-size: 30px;
    width: 250px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: grey;
}
#nav-login-register nav{
    width: 1000px;
    
}

#next-test-nav-down button {
    font-size: 30px;
    width: 250px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}
#next button:hover {
    font-size: 30px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}

#next-test{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    
    
}
#next-test button {
    font-size: 30px;
    width: 190px;
    font-family: 'Fjalla One', sans-serif;
    /* font-size: 30px; */
    /* border-radius: 5%; */
    padding: 5px 5px;
    color: white;
    background: black;
    border: none;
}
#next-test button:hover {
    font-size: 30px;
    /* font-family: 'Fjalla One', sans-serif; */
    /* font-size: 35px; */
    /* border-radius: 5%; */
    padding: 5px 5px;
    color: black;
    background: white;
    cursor: pointer;
}

.home-icon img{
    /* background-image: */
}
.nav-link-name{
    display: flex;
    align-items: center;
    justify-content: center;
    PADDING-LEFT: -200PX;
    width: 150px;
    margin-left: -70px;
}
.nav-link-name i {
    margin-left: 10px;
}

.nav-link-name:hover{
    color: white;
}



.nav{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    WIDTH: 100%;
   
}

.welcome-in{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1800px;
}




.logo-container {
    width: 100%;
    height: 150px;
    max-width: 1800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* justify-content: flex-start; */
    /* padding: 20px; */
    /* padding-left: 10px; */
    /* padding-RIGHT: 10px; */
}

/* .logo-container h1 {
    font-size: 70px;
} */

.nav-link, .nav-link-name {
    text-decoration: none;
    color: white;
    font-size: 25px;
   
    margin-right: 5px;
}

.nav h1{
    color: lightgrey;
    margin-right: 250px;
    
    
}


.nav-link:hover{
    color: lightgrey;
    
}

.logo {
    height: 130px;
    /* padding-bottom: 10px; */
    width: auto;
    /* padding-top: 10px; */
}

.header-nav-trial{
    display: flex;
    /* flex-direction: column; */
    width: 1500px;
    max-width: 1500px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    background: black;
    /* background: rgba(100, 100, 100, 0.7); */
    border-top: 15px solid white;
    border-radius: 15%;
    /* color: lightgrey; */
    height: 100px;
}

.header-nav-trial h1{
    font-size: 50px;
}

#next-test-nav-down {
    display: none;
    width: 100%;
    /* padding: 5px 5px; */
}

.nav-down-container{
    background: black;
    height: 100px;
    width: 100%;
    width: 100vw;
    

}

#next-test-nav-down-register-login{
    display: none
}






@media screen and (max-width: 768px) {
    .logo-container {
        justify-content: space-between;
    }

    .nav{
        width: 370px;
        justify-content: flex-end; 
        align-items: flex-end; 
    }
    
    .nav-link-name{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        PADDING-LEFT: 0PX;
        width: 0px;
        margin-left: 0px;
    }
    .nav-link-name i {
        margin-left: 10px;
    }


    .logo {
        margin-left: 40px;
    }

    .nav-link, .nav-link-name {
        text-decoration: none;
        color: white;
        font-size: 20px;
        /* margin-right: 25px; */
    }

    .nav h1{
        margin-right: 0px;
    }
    .logo-container {
        /* padding-top: 25px; */
        max-width: 768px;
        width: 100%;
        height: 90px;
        /* margin: 0 auto; */
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid white;
        padding-left: 0px;
        padding-RIGHT: 40px;
    }
    

    #next-test-nav-down {
        display: flex;
        position: absolute;
        
        
        top: 100px;
        height: 40px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: center;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
        
    }

    #next-test-nav-down button {
        font-size: 20px;
        width: 100%;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: lightgrey;
    }

    #next-test-nav-down button:hover{
        background: white;
        color: black;
        border: none;
    }
    

    header{
        max-width: 768px;
        height: 150px;
    }

    .logo {
        height: 70px;
        /* align-self: center; */
        /* padding-bottom: 10px; */
        width: auto;
        /* padding-top: 10px; */
    }

    #next-test{
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    #next-test button {
        font-size: 20px;
        border: 1px solid white;
    }

    #next-test-nav-down-register-login{
        display: flex;
        position: absolute;
        /* border-top: 1px solid white; */
        
        
        
        top: 100px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: space-between;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
    }

    #next-test-nav-down-register-login button {
        font-size: 20px;
        width: 100%;
        background: black;
        border: none;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: white;
    }
    #next-test-nav-down-register-login button:hover {
        background: white;
        color: black;
        height: 100%;
    }

    



    
}

@media screen and (max-width: 414px) {
    .nav{
        width: 200px;
        justify-content: center; 
        align-items: center; 
    }
    .logo-container {
        /* padding-top: 25px; */
        max-width: 414px;
        width: 100%;
        height: 90px;
        /* margin: 0 auto; */
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid white;
        padding-left: 0px;
        padding-RIGHT: -0px;
    }

    .nav-link-name{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        PADDING-LEFT: 0PX;
        width:90px;
        margin-left: 0px;
    }
    .nav-link-name i {
        margin-left: 10px;
    }
    

    #next-test-nav-down {
        display: flex;
        position: absolute;
        
        
        top: 100px;
        height: 40px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: center;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
        
    }

    #next-test-nav-down button {
        font-size: 20px;
        width: 100%;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: lightgrey;
    }

    #next-test-nav-down button:hover{
        background: white;
        color: black;
        border: none;
    }
    

    header{
        width: 414px;
        height: 150px;
    }

    .logo {
        height: 70px;
        /* align-self: center; */
        /* padding-bottom: 10px; */
        width: auto;
        /* padding-top: 10px; */
    }

    #next-test{
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    #next-test button {
        font-size: 20px;
        border: 1px solid white;
    }

    #next-test-nav-down-register-login{
        display: flex;
        position: absolute;
        /* border-top: 1px solid white; */
        
        
        
        top: 100px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: space-between;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
    }

    #next-test-nav-down-register-login button {
        font-size: 20px;
        width: 100%;
        background: black;
        border: none;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: white;
    }
    #next-test-nav-down-register-login button:hover {
        background: white;
        color: black;
        height: 100%;
    }

}
