.chatrooms-container{
    width: 100%;
    HEIGHT: 1700px;
    
    /* background: url('./nala.JPG'); */
    /* MARGIN-TOP: 50PX; */

}

.chat-rooms-display{
    BORDER: 3PX SOLID WHITE;
    width: 100%;
    max-width: 1500px;
    HEIGHT: 100%;
    height: 1100px;
    margin: 0 auto;
    /* text-align: center; */
    /* margin-right: 100px; */
    background: url('./nala.JPG');
    BACKGROUND-SIZE: COVER;
}

.go button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    margin-right: 40px;
    z-index: 2;
}
.go button:hover{
    background: black;
    color: white;
}

.go {
    border: 3px solid white;
    
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 200px 200px; */
    margin: 50px 100px;
    background: rgba(100, 100, 100, 0.3);
    COLOR: WHITE;
    box-shadow: 5px 5px 5px black;
    
    
}

.go h3{
    FONT-SIZE: 35PX;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 414px) {
   .go{
    margin: 5px 5px;
    padding: 0px 10px;
   }

   .go h3{
    FONT-SIZE: 30PX;
    letter-spacing: 1px;
    }
    .chatrooms-container{
        /* margin-top: 600px; */
    }
}