.profiles {
    /* background: linear-gradient( white, lightgrey, white); */
    /* min-height: 100vh; */
    width: 100vw;
    height: 1700px;
    /* position: relative; */
    /* padding-bottom: 50px; */
    /* background-image: url('./../resources/shine.png');
    background-repeat: no-repeat;
    background-size: cover; */
}

.select {
    font-size: 30px;
    width: 300px;

}

.select-container button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 25px;
    border-radius: 5%;
    padding: 10px 20px;
    color: grey;
    /* margin-bottom: 30px; */
    /* margin-bottom: 30px; */
    cursor: pointer;
}

.select-container button:hover{
    font-family: 'Fjalla One', sans-serif;
    /* font-size: 30px; */
    border-radius: 5%;
    /* padding: 10px 20px; */
    color: white;
    background: black;
}

.link {
    text-decoration: none;
}

.select-container{
    display: flex;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
}

.select-container h1{
    color: grey
}

.mapped-profiles {
    /* border: 5px solid grey; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* flex-wrap: wrap; */
    
    height: 800px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    /* border-radius: 1%; */

    overflow: scroll;
    /* box-shadow: 5px 5px 5px grey; */
}

/* .friends-display {
    height: 200px;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    background: lightgrey;
    border: 2px solid white;
    

} */

.mapped-friends{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 350px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    /* background: white; */
    /* background: rgb(66, 66, 66); */
    background: url('./../Home/resources/opacityprints.png');
    background-size: 200px 200px;
    /* border-radius: 9%; */
    box-shadow: 3px 3px 3px grey;

    overflow: scroll;
    margin-bottom: 30px;
}

.friends-display-section h1{
    text-align: center;
    font-size: 50px;
    /* border: 2px solid black; */
    /* width: 200px; */
    
}
.friends-display-section{
    margin-bottom: 900px;
}

.button-show-all-for-mobile button{
    display: none;
    font-family: 'Fjalla One', sans-serif;
    
    cursor: pointer;
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 10px;
    color: grey;

}

.button-show-all-for-mobile button:hover{
    color: white;
    background: black;
}

@media screen (max-width: 768px) and (min-width: 415px) {
    .profiles {
        max-width: 768px;
        width: 100%;
    }

    .mapped-profiles {
        /* border: 5px solid grey; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* flex-wrap: wrap; */
        
        height: 700px;
        max-width: 768px;
        width: 100%;
        margin: 0 auto;
        /* border-radius: 1%; */
    
        overflow: scroll;
        /* box-shadow: 5px 5px 5px grey; */
    }


    .select-container{
        margin-top: 60px;
        padding-top: 20px;
        /* display: flex; */
        /* width: 100%; */
        /* max-width: 768px; */
        /* margin: 0 auto; */
        /* justify-content: space-around; */
        /* align-items: center; */
    }
    .select {
        font-size: 20px;
        /* width: 150px; */
        /* margin-top: 50px; */
    
    }
    .select {
        
        width: 190px;
    
    }

    .select-container h1{
       font-size: 20px;
    }

    .friends-display-section h1{
        text-align: center;
        font-size: 30px;
        /* border: 2px solid black; */
        /* width: 200px; */
        
    }

}

@media screen and (max-width: 414px) {
    .title {
        margin-top: 0px;
        
    }

    .profiles {
        width: 100%;
    }

    .mapped-profiles{
        width: 100%;
        max-width: 414px;
    }

    #profile {
        width: 100%;
    }

    .select-container h1{
        display: none;
    }

    .select-container button {
        display: none;
    }
    .button-show-all-for-mobile button{
        display: flex;
        justify-content: center;
        width: 100%;
        
    }


    .select{
        font-size: 20px;
    }

    .button-show-all-for-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 414px;
        
    }
    
}