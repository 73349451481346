
/* .user-profile-information{
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.user-profile-container {
    width: 100%;
    max-height: 100%;
    padding: 60px 50px;
    
    max-width: 1500px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 800px;
    
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    justify-content: center;
    
    align-items: center;
 
    /* border: 1px solid red; */
    background: grey;
    margin-bottom: 25px;
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    background-size: 1500px 1500px;
    border: 2px solid black;
    box-shadow: 10px 10px 10px grey;
    /* margin: 20px 20px; */
    /* padding: 20px 20px; */
    border-radius: 1%;
    /* overflow: scroll; */
   


}

.user-profile-information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 5px solid white;
    max-width: 700px;
    width: 100%;
    max-height: 700px;
    height: 100%;
    /* margin-right: 10px; */
}





.user-profile{
    /* width: 1300px; */
    max-width: 1500px;
    
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* border: 5px solid white; */
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    background-repeat: no-repeat;
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}
/* 
.edit-user-profile {
    width: 1300px;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    /* background-repeat: no-repeat; */
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    



.user-profile-container img:hover{
    /* border: 10px solid black; */
    /* opacity: 2;
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.0);
    box-shadow: 5px 5px 5px black; */ 
}

.user-profile-image-container{
    width: 700px;
    height: 700px;
}

.user-profile-container img {
    background-image: contain;
    /* display: block; */
    height: 100%;
    width: 100%;
    max-height: 700px;
    max-width: 700px;
    border: 10px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    /* border-top: 5px solid white; */
    /* border-right: 5px solid white; */
    /* border-bottom: 5px solid white; */
    /* border-left: 5px solid white; */
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    /* box-shadow: 5px 5px 5px grey; */

    
    
}
.user-profile-info-logo {
    height: 60px;
    width: 60px;
    background-image: url('./../resources/pawprint.png');
    background-size: cover;
    margin-right: 10px;
}

.user-profile-name {
    display: flex;
    
    justify-content: center;
    align-items: center;
    width: 700px;
   
    
    height: 100px;
    font-size: 30px;
    color: black;
    /* background: white; */
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border: 4px solid grey; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    /* box-shadow: 10px 6px 5px grey; */
    text-shadow: 1px 1px 1px lightgrey;
    border-bottom: 3px solid grey;
    /* z-index: 2; */
    /* width: 200px; */
}

.user-profile-info, .edit-user-profile-info {
    height: 600px;
    width: 700px;
    /* height: 300px;
    width: 350px; */
    text-align: center;
    background: white;
    color: grey;
    /* border-radius: 5%; */

    
    /* border-bottom: 5px solid white; */
    /* border: 5px solid white; */
    /* border-right: 5px solid white; */
    border-left: 5px solid white;
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    /* box-shadow: 5px 5px 5px grey; */
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.edit-user-profile-info {
    padding: 50px 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    
}

.edit-user-profile-info{
    /* font-size: 25px; */
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

.user-profile-info h3{
    font-size: 30px;
}

.edit-select-regular {
    

    font-family: 'Fjalla One', sans-serif;
    height: 35.95px;
    padding: 5px 5px;
    
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    /* border-radius: 4px; */
    border-style: solid;
    border-width: 1px;
    cursor: default;
    padding: 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 200px;
    font-family: 'Fjalla One', sans-serif;
}

.edit-select {
    width: 100%;
    
    /* border-color: hsl(0,0%,80%); */
    font-size: 20px;
    /* text-align: center; */
    /* width: 100%; */
    border-radius: 4px;
    font-family: 'Fjalla One', sans-serif;
    
    /* align-self: center; */
}

/* select{
    width: 100%;
} */



.user-profile-buttons button {
    
    
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    margin-top: 20px;
    
}

.user-profile-buttons button:hover{
    background: black;
    color: white;
}

#dropzone-edit{
    /* height: 500px; */
    width: 100%;
    border: 2.2px lightgray dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 700px;
    width: 700px;
    /* border: 5px dotted white; */
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    border: 8px solid rgb(248, 245, 245);
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px grey;
    font-family: 'Fjalla One', sans-serif;
}

#select {
    width: 100%;
    
    
    font-family: 'Fjalla One', sans-serif;
}

h3{
    font-size: 25px;
}


@media screen and (max-width: 768px) {
    .user-profile-container {
        /* flex-direction: column-reverse; */
        height: 1500px;
        max-width: 768px;
        /* width: 100%; */
        /* margin: 0 auto; */
        /* flex-direction: column-reverse; */
        overflow: scroll;
    }

    .user-profile{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .user-profile-container img {
        background-image: contain;
        /* display: block; */
        height: 650px;
        width: 650px;
    }

    .user-profile-image-container{
        width: 600px;
        height: 700px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

}

@media screen and (max-width: 414px) {
    .user-profile-container {
        /* flex-direction: column-reverse; */
        height: 1500px;
        max-width: 414px;
        padding: 0px 0px;
        justify-content: flex-start;
        align-items: flex-start;
        /* width: 100%; */
        /* margin: 0 auto; */
        /* flex-direction: column-reverse; */
        
        
        border-radius: 0%;
    }

    .user-profile{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 414px;
    }

    .user-profile-container img {
        background-image: contain;
        /* display: block; */
        height: 650px;
        width: 414px;
    }

    .user-profile-image-container{
        width: 414px;
        height: 414px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .user-profile-information{
        max-width: 414px;
    }

    .user-profile-container img {
        max-height: 414px;
        max-width: 414px;
    }

    .user-profile-name {
        max-width: 414px;
    }
    .user-profile-info, .edit-user-profile-info {
        max-width: 414px;
    }
    .user-profile-info h3{
        font-size: 20px;
    }

    .edit-user-profile-info {
        padding: 0px 90px;
    }

    .user-profile-name {
        font-size: 25px;
    }

    .edit-select-regular{
        border-radius: 0%;
    }
}