


.home {
    /* font-family: 'Cambria'; */
    /* width: 100vw; */
    /* height: 100vh; */
    /* height: 100%; */
    /* width: 100%; */
    /* height: 50px; */
    /* width: 500px; */
    background: lightgrey;
    /* padding-bottom: 200px; */
    position: relative;
    background-image: url('./resources/batman2.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    /* opacity: 0.5; */
    z-index: 1;
    /* background: url('resources/opacityprints.png'); */
}

h2 {
    font-size: 65px;
    text-shadow: 4px 3px 3px black;
    color: white;
    
}


.welcome {
    z-index: 3;
    /* opacity: 1; */
    
    background-image: url('./resources/batman2.JPG');

    width: 1500px; 
    /* width: 100%; */
    /* height: auto; */
    height: 1000px;
    margin: 0 auto;
    /* background-size: cover; */
    background-position: 100% 80%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    /* border-right: 3px solid white; */
    /* border-left: 3px solid white; */
    border-top: 5px solid white;
    border-right: 5px solid white;
    border-left: 5px solid white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
}

.find-friends {
    
    background-image: url('./resources/nala.JPG');

    width: 1500px; 
    /* width: 100%; */
    /* height: auto; */
    height: 1000px;
    margin: 0 auto;
    /* background-size: cover; */
    background-position: 100% 80%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    /* border-right: 3px solid white; */
    /* border-left: 3px solid white; */
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    border-left: 5px solid white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
}

.opacity-container1, .opacity-container3{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border: 1px solid lightgrey;
    background: rgba(100, 100, 100, 0.7);
    color: white;
    padding-right: 100px;
    width: 100%;
    
}

.opacity-container2{
    display: flex;
    justify-content: flex-start;
    /* align-items: flex-end; */
    background: rgba(100, 100, 100, 0.7);
    color: white;
    border: 1px solid lightgrey;
    padding-left: 100px;
    width: 100%;
}

.opacity-container2:hover{
    color: white;
    background:black;
}
.opacity-container1:hover{
    color: white;
    background:black;
}
.opacity-container3:hover{
    color: white;
    background:black;
}



.opacity-container2 h2{
    margin-right: 10px;

}

.register {
    background-image: url('./resources/iggy2.JPG');
    height: 1000px;
    width: 1500px;
    margin: 0 auto;
    background-size: 1500PX AUTO;
    background-position: 50% ;
    background-repeat: no-repeat;
    /* border-right: 3px solid white; */
    /* border-left: 3px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    border-left: 5px solid white;
}

@media screen and (max-width: 768px) {
    h2 {
        font-size: 35px;
        text-shadow: 1px 1px 1px black;
        color: white;  
         
    }

    .link {
        font-size: 20px;
    }

    .register {
        
        height: 530px;
        width: 768px;
        background-size: cover;
        margin: 0 auto;
        border: none;
        
    }

    .welcome {
        height: 600px;
        width: 768px;
        margin: 0 auto;
        border: none;
    }

    .find-friends{
        height:500px;
        width: 768px;
        margin: 0 auto;
        border: none;
    }
}

@media screen and (max-width: 414px) {
    main{
        width: 414px;
    }
    h2 {
        font-size: 30px;
        /* text-shadow: 1px 1px 1px black; */
        /* color: white;   */
        
    }
    .home {
        width: 414px;
        height: 600px;
        
        
        /* height: 1500px; */
        /* margin: 0 auto; */
        /* background: black; */
        /* display: flex; */
        /* justify-content: center; */
        /* flex-direction: column; */
        /* align-items: center; */
        /* margin-left: 30px; */
    }

    .register {
        
        height: 400px;
        width:414px;
        /* margin: 0 auto; */
        /* background-size: cover; */
        /* border: none; */
        
    }

    .welcome {
        height: 400px;
        width: 414px;
        margin: 0 auto;
        border: none;
    }

    .find-friends{
        height:400px;
        width: 414px;
        margin: 0 auto;
        border: none;
        background-size: cover;
    }
}



