.App {
    font-family: 'Fjalla One', sans-serif;
    /* height: 100vh; */
    /* max-height: 1600px; */
    width: 100vw;
    width: 100%;
    background: lightgrey;
    position: relative;
    /* min-height: 100vh; */
    /* width: 2000px; */
}

.main{
    padding-bottom: 100px;
}

html {
    background: black;
   
}

.footer-title i {
    font-size: 40px;
    padding-left: 30px;
}

#next-test-nav-down-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    background: red;
    height: 70px;
    width: 414px;
    overflow: scroll;
    /* padding-bottom: 400px; */
}

#next-test-nav-down-footer button{
    font-size: 30px;
    width: 250px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}
#next-test-nav-down-footer button:hover{
    font-size: 30px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}

.footer{
    bottom: 0px;
    /* padding-top: 800px; */
}

.footer-title {
    font-size: 40px;
    /* border: 1px solid lightgrey; */
    /* margin: 20px 20px; */
    padding: 10px 10px;
    width: 100%;
    text-align: center;
    /* border-radius: 7%; */
    /* BACKGROUND: BLACK; */
    COLOR: WHITE;
    background: rgba(100, 100, 100, 0.4);
}

@media screen and (max-width: 768px) {
    .App {
        width: 768px;
        
    }
}

@media screen and (max-width: 414px) {
    .App {
        width: 414px;
        /* width: 100%; */
        /* background: black; */
        
    }
    
   html {
       /* width: 375px; */
       /* width: 100%; */
       
    }
    
}

.footer{
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
    background: black;

}