
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    box-sizing: border-box;
}

* {
    box-sizing: inherit;
}



.App {
    font-family: 'Fjalla One', sans-serif;
    /* height: 100vh; */
    /* max-height: 1600px; */
    width: 100vw;
    width: 100%;
    background: lightgrey;
    position: relative;
    /* min-height: 100vh; */
    /* width: 2000px; */
}

.main{
    padding-bottom: 100px;
}

html {
    background: black;
   
}

.footer-title i {
    font-size: 40px;
    padding-left: 30px;
}

#next-test-nav-down-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    background: red;
    height: 70px;
    width: 414px;
    overflow: scroll;
    /* padding-bottom: 400px; */
}

#next-test-nav-down-footer button{
    font-size: 30px;
    width: 250px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}
#next-test-nav-down-footer button:hover{
    font-size: 30px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}

.footer{
    bottom: 0px;
    /* padding-top: 800px; */
}

.footer-title {
    font-size: 40px;
    /* border: 1px solid lightgrey; */
    /* margin: 20px 20px; */
    padding: 10px 10px;
    width: 100%;
    text-align: center;
    /* border-radius: 7%; */
    /* BACKGROUND: BLACK; */
    COLOR: WHITE;
    background: rgba(100, 100, 100, 0.4);
}

@media screen and (max-width: 768px) {
    .App {
        width: 768px;
        
    }
}

@media screen and (max-width: 414px) {
    .App {
        width: 414px;
        /* width: 100%; */
        /* background: black; */
        
    }
    
   html {
       /* width: 375px; */
       /* width: 100%; */
       
    }
    
}

.footer{
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
    background: black;

}



.home {
    /* font-family: 'Cambria'; */
    /* width: 100vw; */
    /* height: 100vh; */
    /* height: 100%; */
    /* width: 100%; */
    /* height: 50px; */
    /* width: 500px; */
    background: lightgrey;
    /* padding-bottom: 200px; */
    position: relative;
    background-image: url(/static/media/batman2.d8dc9ca0.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    /* opacity: 0.5; */
    z-index: 1;
    /* background: url('resources/opacityprints.png'); */
}

h2 {
    font-size: 65px;
    text-shadow: 4px 3px 3px black;
    color: white;
    
}


.welcome {
    z-index: 3;
    /* opacity: 1; */
    
    background-image: url(/static/media/batman2.d8dc9ca0.JPG);

    width: 1500px; 
    /* width: 100%; */
    /* height: auto; */
    height: 1000px;
    margin: 0 auto;
    /* background-size: cover; */
    background-position: 100% 80%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    /* border-right: 3px solid white; */
    /* border-left: 3px solid white; */
    border-top: 5px solid white;
    border-right: 5px solid white;
    border-left: 5px solid white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
}

.find-friends {
    
    background-image: url(/static/media/nala.4d8e46d2.JPG);

    width: 1500px; 
    /* width: 100%; */
    /* height: auto; */
    height: 1000px;
    margin: 0 auto;
    /* background-size: cover; */
    background-position: 100% 80%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    /* border-right: 3px solid white; */
    /* border-left: 3px solid white; */
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    border-left: 5px solid white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
}

.opacity-container1, .opacity-container3{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border: 1px solid lightgrey;
    background: rgba(100, 100, 100, 0.7);
    color: white;
    padding-right: 100px;
    width: 100%;
    
}

.opacity-container2{
    display: flex;
    justify-content: flex-start;
    /* align-items: flex-end; */
    background: rgba(100, 100, 100, 0.7);
    color: white;
    border: 1px solid lightgrey;
    padding-left: 100px;
    width: 100%;
}

.opacity-container2:hover{
    color: white;
    background:black;
}
.opacity-container1:hover{
    color: white;
    background:black;
}
.opacity-container3:hover{
    color: white;
    background:black;
}



.opacity-container2 h2{
    margin-right: 10px;

}

.register {
    background-image: url(/static/media/iggy2.47df42d9.JPG);
    height: 1000px;
    width: 1500px;
    margin: 0 auto;
    background-size: 1500PX AUTO;
    background-position: 50% ;
    background-repeat: no-repeat;
    /* border-right: 3px solid white; */
    /* border-left: 3px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    border-left: 5px solid white;
}

@media screen and (max-width: 768px) {
    h2 {
        font-size: 35px;
        text-shadow: 1px 1px 1px black;
        color: white;  
         
    }

    .link {
        font-size: 20px;
    }

    .register {
        
        height: 530px;
        width: 768px;
        background-size: cover;
        margin: 0 auto;
        border: none;
        
    }

    .welcome {
        height: 600px;
        width: 768px;
        margin: 0 auto;
        border: none;
    }

    .find-friends{
        height:500px;
        width: 768px;
        margin: 0 auto;
        border: none;
    }
}

@media screen and (max-width: 414px) {
    main{
        width: 414px;
    }
    h2 {
        font-size: 30px;
        /* text-shadow: 1px 1px 1px black; */
        /* color: white;   */
        
    }
    .home {
        width: 414px;
        height: 600px;
        
        
        /* height: 1500px; */
        /* margin: 0 auto; */
        /* background: black; */
        /* display: flex; */
        /* justify-content: center; */
        /* flex-direction: column; */
        /* align-items: center; */
        /* margin-left: 30px; */
    }

    .register {
        
        height: 400px;
        width:414px;
        /* margin: 0 auto; */
        /* background-size: cover; */
        /* border: none; */
        
    }

    .welcome {
        height: 400px;
        width: 414px;
        margin: 0 auto;
        border: none;
    }

    .find-friends{
        height:400px;
        width: 414px;
        margin: 0 auto;
        border: none;
        background-size: cover;
    }
}




.registration-container-1{
    background-image: url(/static/media/babydutchie.b2d8ccb2.JPG);
    background-size: 150%;
    background-repeat: no-repeat;
}

.registration-container{
    width: 100%;
    border: 2px solid white;
    background-image: url(/static/media/babydutchie.b2d8ccb2.JPG);
    background-repeat: no-repeat;
    background-size: 160%;
    /* background-position: 90%; */
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    height: 1500px;
    text-shadow: 3px 2px 1px black;
    display: flex;
    justify-content: center;
    /* align-items: space-between; */
    padding-top: 100px;
}
.registration-form h1 {
    font-size: 65px;
    text-shadow: 4px 3px 3px black;
}

.registration-form p {
    font-size: 40px;
    text-align: center;
    align-self: center;
}

.welcome-icons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-icons i {
    font-size: 45px;
    margin-left: 20px;
    margin-right: 20px;
}

.registration-form button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
}

.registration-form button:hover {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 15px;
    color: white;
    background: black;
    cursor: pointer;
}

.registration-form{
    /* margin-top: 200px; */
    width: 100%;
    max-width: 1000px;
    height: 700px;
    /* margin: 0 auto; */
    
    /* margin: 0 auto; */
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-size: cover;
    /* height: 1000px; */
    background: rgba(100, 100, 100, 0.4);
    color: white;
    border-radius: 10%;
    border: 1px solid lightgrey;
    padding-bottom: 30px;
    /* box-shadow: 2px 5px 2px grey; */
}

.form {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    color: white;
    
}



.label-input{
    display: flex;
    flex-direction: column;
    
}
.label-input label {
    margin-right: 20px;
    /* margin-bottom: 15px; */
    /* align-self: center; */
}

.label-input input {
    margin-right: 20px;
    
    /* margin-bottom: 50px; */
    /* padding: 20px 20px; */
    /* border-radius: 7%; */
    font-family: 'Fjalla One', sans-serif;
    
}

.label-input input:hover {
    color: white;
    background: black;
    /* margin-right: 30px; */
    /* font-size: 40px; */
    /* margin-bottom: 50px; */
    /* padding: 20px 20px; */
    /* border-radius: 7%; */
    font-family: 'Fjalla One', sans-serif;
    
}

@media screen and (max-width: 768px) {
    .label-input {
        /* margin-bottom: px; */
        display: flex;
        flex-direction: column;
        margin-top: 45px;
    }

    .registration-form {
        /* flex-direction: column; */
    }

    .registration-container-1{
        height: 1700px;
    }

    .registration-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        background-size: cover;
        height: 100%;
        background: rgba(100, 100, 100, 0.4);
        color: white;
    
        width: 100%;
        max-width: 1500px;
        border-radius: 0%;
        
        
      
        border: 1px solid white;
        color: white;
    }

    .login-form h1 {
        text-align: center;
    }
    
}

@media screen and (max-width: 414px) {
    .registration-form{
        width: 100%;
        max-width: 414px;
        height: 100%;
        /* height: 200px; */
        border-radius: 0%;
        border: none;
        padding: 2px 2px;
        justify-content: flex-start;
        /* align-items: flex-start; */
        

    }

    .registration-container-1{
        height:1200px;
        overflow: scroll;
        padding-bottom: 150px;
    }
    .registration-container{
        padding-top: 0px;
        height: 100%;
        
        
        border: none;
        overflow: scroll;
    }

    .registration-form h1 {
    
        font-size: 30px;
        text-shadow: 2px 2px 2px black;
        text-align: center;
        
    }

    .label-input {
        margin-top: 0px;
    }

    .label-input input {
        /* margin-right: 10px; */
        font-size: 30px;
        margin-bottom: 0px;
       
        /* border-radius: 1%; */
        font-family: 'Fjalla One', sans-serif;
        /* text-shadow: 1px 1px 1px lightgrey; */
        padding: 10px 5px;
        margin-top: 0px;
        
        
    }
    form{
        height: 100%;
        overflow: scroll;
        /* padding-left: 20px; */
    }
    
}
.login-container{

   
    background-repeat: no-repeat;
    background-size: cover;
 
    text-shadow: 3px 2px 1px black;


   
    border: 1px solid white;
    background-image: url(/static/media/batman2.d8dc9ca0.JPG);
    background-size: 110%;
 
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    height: 1500px;
    /* position: relative; */
    padding-top: 100px;
    display: flex;
    justify-content: center;
    
    
   
}

.login-container-1{
    width: 100%;
    /* background: blue; */
    background-image: url(/static/media/batman2.d8dc9ca0.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    height: 1500px;
}
.login-form h1 {
    
    font-size: 55px;
    text-shadow: 4px 3px 3px black;
    text-align: center;
    
}

.login-form h2 {
    font-size: 35px;
    text-align: center;
}

.login-form button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: grey;
    margin-bottom: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    /* margin-right: 40px; */
    /* text-shadow: 1px 1px 3px lightgrey; */
}

.login-form button:hover {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}

.login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-size: cover;
    height: 900px;
    background: rgba(100, 100, 100, 0.4);
    color: white;

    width: 100%;
    max-width: 1000px;
    border-radius: 10%;
    padding-right: 150px;
    padding-left: 200px;
    padding-top: 50px;
    padding-bottom: 100px;
    overflow: hidden;
    
    
    
    
  
    border: 1px solid white;
    color: white;
    padding-bottom: 30px;
}

.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;
}
#next{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
}
#next button {
    font-size: 30px;
    width: 250px;
}
#next button:hover {
    font-size: 30px;
}

.label-input {
    /* margin-bottom: px; */
    display: flex;
    /* flex-direction: column; */
    margin-top: 30px;
}

.label-input label {
    margin-bottom: 15px;
    /* align-self: center; */
}

.label-input input {
    /* margin-right: 10px; */
    font-size: 30px;
    margin-bottom: 50px;
    /* border-radius: 1%; */
    font-family: 'Fjalla One', sans-serif;
    /* text-shadow: 1px 1px 1px lightgrey; */
    padding: 5px 5px;
    
    
}

.login-button{
    /* margin-left: 89px; */
}


@media screen and (max-width: 768px) {
    .label-input {
        /* margin-bottom: px; */
        display: flex;
        flex-direction: column;
        margin-top: 45px;
    }

    .form {
        flex-direction: column;
    }

    .login-form{
        border-radius: 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        background-size: cover;
        height: 1500px;
        background: rgba(100, 100, 100, 0.4);
        color: white;
        
    
        width: 100%;
        max-width: 1500px;
        
        
      
        border: 1px solid white;
        color: white;
    }

    .login-form h1 {
        text-align: center;
    }

    .login-container{
        padding-top: 100px;
    }
    
}

@media screen and (max-width: 414px) {
    .login-form{
        width: 100%;
        max-width: 414px;
        height: 100%;
        border-radius: 0%;
        border: none;
        padding: 2px 2px;
        justify-content: flex-start;
        /* align-items: flex-start; */
        

    }

    .login-container-1{
        height:900px;
    }
    .login-container{
        padding-top: 0px;
        height: 800px;
        
        border: none;
    }

    .login-form h1 {
    
        font-size: 30px;
        text-shadow: 2px 2px 2px black;
        text-align: center;
        
    }

    .label-input input {
        /* margin-right: 10px; */
        font-size: 30px;
        margin-bottom: 50px;
        /* border-radius: 1%; */
        font-family: 'Fjalla One', sans-serif;
        /* text-shadow: 1px 1px 1px lightgrey; */
        padding: 10px 5px;
        
        
    }
    form{
        padding-left: 20px;
    }
    
}


.profiles {
    /* background: linear-gradient( white, lightgrey, white); */
    /* min-height: 100vh; */
    width: 100vw;
    height: 1700px;
    /* position: relative; */
    /* padding-bottom: 50px; */
    /* background-image: url('./../resources/shine.png');
    background-repeat: no-repeat;
    background-size: cover; */
}

.select {
    font-size: 30px;
    width: 300px;

}

.select-container button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 25px;
    border-radius: 5%;
    padding: 10px 20px;
    color: grey;
    /* margin-bottom: 30px; */
    /* margin-bottom: 30px; */
    cursor: pointer;
}

.select-container button:hover{
    font-family: 'Fjalla One', sans-serif;
    /* font-size: 30px; */
    border-radius: 5%;
    /* padding: 10px 20px; */
    color: white;
    background: black;
}

.link {
    text-decoration: none;
}

.select-container{
    display: flex;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
}

.select-container h1{
    color: grey
}

.mapped-profiles {
    /* border: 5px solid grey; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* flex-wrap: wrap; */
    
    height: 800px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    /* border-radius: 1%; */

    overflow: scroll;
    /* box-shadow: 5px 5px 5px grey; */
}

/* .friends-display {
    height: 200px;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    background: lightgrey;
    border: 2px solid white;
    

} */

.mapped-friends{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 350px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    /* background: white; */
    /* background: rgb(66, 66, 66); */
    background: url(/static/media/opacityprints.3a76a89f.png);
    background-size: 200px 200px;
    /* border-radius: 9%; */
    box-shadow: 3px 3px 3px grey;

    overflow: scroll;
    margin-bottom: 30px;
}

.friends-display-section h1{
    text-align: center;
    font-size: 50px;
    /* border: 2px solid black; */
    /* width: 200px; */
    
}
.friends-display-section{
    margin-bottom: 900px;
}

.button-show-all-for-mobile button{
    display: none;
    font-family: 'Fjalla One', sans-serif;
    
    cursor: pointer;
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 10px;
    color: grey;

}

.button-show-all-for-mobile button:hover{
    color: white;
    background: black;
}

@media screen (max-width: 768px) and (min-width: 415px) {
    .profiles {
        max-width: 768px;
        width: 100%;
    }

    .mapped-profiles {
        /* border: 5px solid grey; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* flex-wrap: wrap; */
        
        height: 700px;
        max-width: 768px;
        width: 100%;
        margin: 0 auto;
        /* border-radius: 1%; */
    
        overflow: scroll;
        /* box-shadow: 5px 5px 5px grey; */
    }


    .select-container{
        margin-top: 60px;
        padding-top: 20px;
        /* display: flex; */
        /* width: 100%; */
        /* max-width: 768px; */
        /* margin: 0 auto; */
        /* justify-content: space-around; */
        /* align-items: center; */
    }
    .select {
        font-size: 20px;
        /* width: 150px; */
        /* margin-top: 50px; */
    
    }
    .select {
        
        width: 190px;
    
    }

    .select-container h1{
       font-size: 20px;
    }

    .friends-display-section h1{
        text-align: center;
        font-size: 30px;
        /* border: 2px solid black; */
        /* width: 200px; */
        
    }

}

@media screen and (max-width: 414px) {
    .title {
        margin-top: 0px;
        
    }

    .profiles {
        width: 100%;
    }

    .mapped-profiles{
        width: 100%;
        max-width: 414px;
    }

    #profile {
        width: 100%;
    }

    .select-container h1{
        display: none;
    }

    .select-container button {
        display: none;
    }
    .button-show-all-for-mobile button{
        display: flex;
        justify-content: center;
        width: 100%;
        
    }


    .select{
        font-size: 20px;
    }

    .button-show-all-for-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 414px;
        
    }
    
}


#profile-container-profile{
    /* padding-top: 20px; */
    margin: 20px 20px;
    max-width: 1500px;
    height: 800px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 3px solid black;
    background: grey;
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    padding: 20px 20px;
    box-shadow: 5px 5px 5px grey;
}

#profile-container-profile:hover{
    box-shadow: 10px 10px 10px black;
}





#profile {
    /* margin-top: 1px; */
    /* margin-right: 80px; */
    /* border-radius: 4%; */
    
    border-bottom: 10px solid white;
    border-left: 10px solid white;
    border-top: 10px solid white;
    width: 700px;
    height: 700px;
    max-width: 700px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: lightgrey;
    /* color: white; */
    

    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}




.profile-image-container img:hover{
    
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.2); */
    box-shadow: 10px 10px 10px black;

}

.profile-image-container img {
    /* display: block; */
    height: 700px;
    width: 700px;
    border: 5px solid white;
    
    /* border-radius: 5%; */
    background-image: contain;
 
    border: 10px solid white;
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px grey;
    /* z-index: 4; */

    
    
}
.info-logo {
    height: 60px;
    width: 60px;
    background-image: url(/static/media/pawprint.2542ccee.png);
    background-size: cover;
    margin-right: 15px;
}

.name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 690px;
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border-bottom: 4px solid white; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    /* box-shadow: 1px 10px 4px grey; */
    border-bottom: 4px solid grey;
    text-shadow: 1px 1px 1px lightgrey;
    
    
    /* width: 200px; */
}

.profile .info {
    height: 300px;
    width: 350px;
    text-align: center;
    background: white;
    color: grey;
    

    font-size: 13px;
    /* border: 1px solid white; */
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    box-shadow: 5px 5px 5px grey;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

#profile .info h3{
    font-size: 30px;
}

#add {
    margin-bottom:60px;
    background: url(/static/media/heartwithpaw.6ddcab37.png);
    background-size: cover;
    height: 100px;
    width: 100px;
}

#add:hover{
    -webkit-transform: scale(1.4);
    transform: scale(1.5);
}

#profile-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

@media screen and (max-width: 768px) {
    #profile-container-profile{
        flex-direction: column;
        max-width: 768px;
        height: 1500px;
    }

}

@media screen and (max-width: 414px) {
    #profile {
        width: 100%;
        height: 500px;
        max-width: 414px;
        border-left: 0px;
        border: none;
        background: grey;
        color: black;
    }

    .name {
        /* max-width: 357px; */
        width: 100%;
        height: 80px;
    }

    .profile-image-container img {
        height: 414px;
        width: 414px;
    }

    #profile-container-profile{
        padding: 0px 0px;
    }

    #profile .info h3{
        font-size: 20px;
    }

    .name h1{
        font-size: 50px;
    }
}
/* .friends h1{
        text-align: center;
    
        font-size: 45px;
        text-shadow: 4px 3px 3px black;
        color: white;

} */


.title-container{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}


.friends-profile-container {
    width: 100%;
    
    max-height: 100%;
    
    max-width: 350px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 800px;
    
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    justify-content: flex-start;
    align-items: center;
    
 
    /* border: 1px solid red; */
    /* background: white; */
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    background-size: 1500px 1500px;
    /* border: 3px solid black; */
    /* box-shadow: 3px 3px 3px grey; */
    /* margin: 20px 20px; */
    /* border-radius: 1%; */
    /* overflow: scroll; */
    display: flex;
    /* flex-direction: column; */
   

}

.friends-image-container{
    padding-right: 40px;
}



.friends-profile {
    width: 1500px;
    max-width: 1500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    background-repeat: no-repeat;
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}


.friends-profile-container img:hover{
    opacity: 2;
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.0); */
    /* box-shadow: 5px 5px 5px black; */
    -webkit-transform: scale(1.2);
    transform: scale(1.1);
}

.friends-profile-container img {
    background-image: contain;
    /* display: block; */
    height: 300px;
    width: 300px;
    border: 2px solid white;
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    border: 8px solid white;
    margin: 20px 20px;
    border-radius: 6%;
    box-shadow: 5px 5px 5px grey;

    
    
}
.friends-info-logo {
    height: 60px;
    width: 60px;
    background-image: url(/static/media/pawprint.2542ccee.png);
    background-size: cover;
    margin-right: 10px;
}

.friends-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    border: 4px solid grey;
    padding: 15px 15px;
    border-radius: 20%; 
    box-shadow: 10px 6px 5px grey;
    text-shadow: 1px 1px 1px lightgrey;
    z-index: 2;
    /* width: 200px; */
}

.friends-profile .info {
    height: 300px;
    width: 350px;
    text-align: center;
    background: white;
    color: grey;
    border-radius: 5%;

    font-size: 13px;
    border: 1px solid white;
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    box-shadow: 5px 5px 5px grey;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

.friends-profile .info h3{
    font-size: 25px;
}

.friends-buttons button {
    background: url(/static/media/heartwithpaw.6ddcab37.png);
    background-size: cover;
}


@media screen and (max-width: 414px) {
    .friends-profile-container img {
        height: 250px;
        width: 250px;
        margin: 0px 5px;

    }

}
.user-pet-profiles{
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}

.home-my-profile{
    background: lightgrey;
    padding-bottom: 100px;
    position: relative;
    height: 1700px;
}

.user-pet-profiles-display{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* flex-wrap: wrap; */
    
    height: 1200px;
    max-width: 1700px;
    width: 100%;
    margin: 0 auto;

    overflow: scroll;
}

.user-info{
    /* display: none; */
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    background: rgba(100, 100, 100, 0.7);
    /* border-top: 15px solid white; */
    /* border-radius: 15%; */
    color: lightgrey;
    height: 80px;
}

.user-info input{
    font-size: 30px;
    font-family: 'Fjalla One', sans-serif;
    padding: 5px 5px;
}

.user-info:hover{
    color: white;
    background: rgba(100, 100, 100, 0.9);
}


.user-info h2 {
    font-size: 30px;
}

.user-info h1 {
    font-size: 30px;
    /* color: grey; */
}

.title{
    font-size: 40px;
    border: 1px solid lightgrey;
    /* margin: 20px 20px; */
    padding: 10px 10px;
    width: 100%;
    text-align: center;
    /* border-radius: 7%; */
    /* BACKGROUND: BLACK; */
    COLOR: WHITE;
    background: rgba(100, 100, 100, 0.4);


}

.user-info button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    margin-right: 40px;
}

.user-pet-profiles{
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.user-pet-profiles button{
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    margin-right: 40px;
}

.user-pet-profiles button:hover{
    background: black;
    color: white;
}

.profile-buttons {
    
}

@media screen and (max-width: 768px) {
    .user-info{
        display: flex;
    /* flex-direction: column; */
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    margin-top: 20px;
    
    }
    .user-info h2 {
        font-size: 20px;
    }
    
    .user-info h1 {
        font-size: 20px;
        /* color: grey; */
    }

    .user-info button {
        font-family: 'Fjalla One', sans-serif;
        font-size: 10px;
        border-radius: 5%;
        padding: 5px 10px;
        color: grey;
        margin-right: 40px;
    }

    .user-pet-profiles-display{
        max-width: 768px;
        height: 1000px;
    }

}

@media screen and (max-width: 414px) {
    .user-pet-profiles h1 {
        font-size: 20px;
    }

    .user-pet-profiles{
        max-width: 414px;
    }
    .user-pet-profiles button{
        margin-right: 0px;
        
    }
    .user-pet-profiles-display{
        max-width: 414px;
        overflow: none;
        
    }

    .user-info{
        max-width: 414px;
    }

}


/* .user-profile-information{
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.user-profile-container {
    width: 100%;
    max-height: 100%;
    padding: 60px 50px;
    
    max-width: 1500px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 800px;
    
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    justify-content: center;
    
    align-items: center;
 
    /* border: 1px solid red; */
    background: grey;
    margin-bottom: 25px;
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    background-size: 1500px 1500px;
    border: 2px solid black;
    box-shadow: 10px 10px 10px grey;
    /* margin: 20px 20px; */
    /* padding: 20px 20px; */
    border-radius: 1%;
    /* overflow: scroll; */
   


}

.user-profile-information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 5px solid white;
    max-width: 700px;
    width: 100%;
    max-height: 700px;
    height: 100%;
    /* margin-right: 10px; */
}





.user-profile{
    /* width: 1300px; */
    max-width: 1500px;
    
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* border: 5px solid white; */
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    background-repeat: no-repeat;
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}
/* 
.edit-user-profile {
    width: 1300px;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    /* background-repeat: no-repeat; */
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    



.user-profile-container img:hover{
    /* border: 10px solid black; */
    /* opacity: 2;
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.0);
    box-shadow: 5px 5px 5px black; */ 
}

.user-profile-image-container{
    width: 700px;
    height: 700px;
}

.user-profile-container img {
    background-image: contain;
    /* display: block; */
    height: 100%;
    width: 100%;
    max-height: 700px;
    max-width: 700px;
    border: 10px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    /* border-top: 5px solid white; */
    /* border-right: 5px solid white; */
    /* border-bottom: 5px solid white; */
    /* border-left: 5px solid white; */
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    /* box-shadow: 5px 5px 5px grey; */

    
    
}
.user-profile-info-logo {
    height: 60px;
    width: 60px;
    background-image: url(/static/media/pawprint.2542ccee.png);
    background-size: cover;
    margin-right: 10px;
}

.user-profile-name {
    display: flex;
    
    justify-content: center;
    align-items: center;
    width: 700px;
   
    
    height: 100px;
    font-size: 30px;
    color: black;
    /* background: white; */
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border: 4px solid grey; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    /* box-shadow: 10px 6px 5px grey; */
    text-shadow: 1px 1px 1px lightgrey;
    border-bottom: 3px solid grey;
    /* z-index: 2; */
    /* width: 200px; */
}

.user-profile-info, .edit-user-profile-info {
    height: 600px;
    width: 700px;
    /* height: 300px;
    width: 350px; */
    text-align: center;
    background: white;
    color: grey;
    /* border-radius: 5%; */

    
    /* border-bottom: 5px solid white; */
    /* border: 5px solid white; */
    /* border-right: 5px solid white; */
    border-left: 5px solid white;
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    /* box-shadow: 5px 5px 5px grey; */
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.edit-user-profile-info {
    padding: 50px 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    
}

.edit-user-profile-info{
    /* font-size: 25px; */
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

.user-profile-info h3{
    font-size: 30px;
}

.edit-select-regular {
    

    font-family: 'Fjalla One', sans-serif;
    height: 35.95px;
    padding: 5px 5px;
    
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    /* border-radius: 4px; */
    border-style: solid;
    border-width: 1px;
    cursor: default;
    padding: 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 200px;
    font-family: 'Fjalla One', sans-serif;
}

.edit-select {
    width: 100%;
    
    /* border-color: hsl(0,0%,80%); */
    font-size: 20px;
    /* text-align: center; */
    /* width: 100%; */
    border-radius: 4px;
    font-family: 'Fjalla One', sans-serif;
    
    /* align-self: center; */
}

/* select{
    width: 100%;
} */



.user-profile-buttons button {
    
    
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    margin-top: 20px;
    
}

.user-profile-buttons button:hover{
    background: black;
    color: white;
}

#dropzone-edit{
    /* height: 500px; */
    width: 100%;
    border: 2.2px lightgray dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 700px;
    width: 700px;
    /* border: 5px dotted white; */
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    border: 8px solid rgb(248, 245, 245);
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px grey;
    font-family: 'Fjalla One', sans-serif;
}

#select {
    width: 100%;
    
    
    font-family: 'Fjalla One', sans-serif;
}

h3{
    font-size: 25px;
}


@media screen and (max-width: 768px) {
    .user-profile-container {
        /* flex-direction: column-reverse; */
        height: 1500px;
        max-width: 768px;
        /* width: 100%; */
        /* margin: 0 auto; */
        /* flex-direction: column-reverse; */
        overflow: scroll;
    }

    .user-profile{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .user-profile-container img {
        background-image: contain;
        /* display: block; */
        height: 650px;
        width: 650px;
    }

    .user-profile-image-container{
        width: 600px;
        height: 700px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

}

@media screen and (max-width: 414px) {
    .user-profile-container {
        /* flex-direction: column-reverse; */
        height: 1500px;
        max-width: 414px;
        padding: 0px 0px;
        justify-content: flex-start;
        align-items: flex-start;
        /* width: 100%; */
        /* margin: 0 auto; */
        /* flex-direction: column-reverse; */
        
        
        border-radius: 0%;
    }

    .user-profile{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 414px;
    }

    .user-profile-container img {
        background-image: contain;
        /* display: block; */
        height: 650px;
        width: 414px;
    }

    .user-profile-image-container{
        width: 414px;
        height: 414px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .user-profile-information{
        max-width: 414px;
    }

    .user-profile-container img {
        max-height: 414px;
        max-width: 414px;
    }

    .user-profile-name {
        max-width: 414px;
    }
    .user-profile-info, .edit-user-profile-info {
        max-width: 414px;
    }
    .user-profile-info h3{
        font-size: 20px;
    }

    .edit-user-profile-info {
        padding: 0px 90px;
    }

    .user-profile-name {
        font-size: 25px;
    }

    .edit-select-regular{
        border-radius: 0%;
    }
}
.add-pet-form-container{
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    /* display: flex;
    flex-direction: column; */
    /* background: url('./../resources/iggy2.JPG'); */
    background-repeat: no-repeat;
    background-size: cover;
    
    height: 1500px;
    /* background: url('./../resources/batmanbig.png'); */
    /* background: url('./../resources/batmanbig.png'); */
    /* background: url('./../resources/batmanbig.png');
    background-size: cover;
    background-repeat: no-repeat; */
}

.add-pet-form {
    background: rgba(100, 100, 100, 0.7);
    height: 300px;
    width: 100%;

    width: 100%;
    max-width: 1500px;
    height: 500px;
    
    /* margin: 0 auto; */
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: url('./../resources/batmanbig.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    
    
    /* height: 1000px; */
    
    

    border: 1px solid white;
    
    
}

.add-pet-form h1{
        font-size: 45px;
        text-shadow: 4px 3px 3px black;
        color: white;
    
}
.mapped-display-profile i{
    font-size: 30px;
    margin-left: 20px;
    align-self: center;
    justify-self: center;

}

.add-button-class{
    display: flex;
    justify-content: center;
    align-items: center;
}



.mapped-display-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 800px;
}

.mapped-display-profile button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 25px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    width: 1500px;

    
}
.mapped-display-profile button:HOVER {
    COLOR: WHITE;
    BACKGROUND: BLACK;

    
}
.add-pet-form input, textarea {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    padding: 5px 9px;
    color: hsl(0,0%,50%);
}

.selections {
    display: flex;
    flex-wrap: wrap;
    /* padding: 10px 10px; */
    justify-content: center;
    align-items: center;
    
}

.container-pet-form {
    /* background: url('./../resources/iggy.JPG'); */
    /* background-repeat: no-repeat; */
    background-size: 100%;
}

#dropzone{
    height: 300px;
    width: 300px;
    border: 2.2px lightgray dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.add-pet-link {
    text-decoration: none;
    color: black;
}
.add-pet-link:hover {
    color: white;
}

@media screen and (max-width: 414px) {
    .add-pet-form-container{
        max-width: 414px;
        display: flex;
        flex-direction: column;
    }

    .add-pet-form {
        max-width: 414px;
    }

    .add-pet-form h1{
        font-size: 30px;
    }

    .add-pet-form input, textarea {
        font-family: 'Fjalla One', sans-serif;
        font-size: 20px;
        padding: 5px 9px;
        color:black;
    }

    .select-favorites{
        text-align: center;
    }

    .selections textarea {
        width: 300px;
        height: 70px;
        padding: 20px 0px;
        text-align: center;
    }
   
   #dropzone{
       height: 50px;
       width: 50px;
       z-index: 1;
   }

   .add-pet-form {
       height: 700px;
   }

   #display-dropzone{
       height: 40px;
       width :40px;
   }
   .mapped-display-profile button {
    max-width: 414px;
    margin-bottom: 100px;

   }


   

  

 

   

    

    
}


#profile-display-container-profile{
    /* padding-top: 20px; */
    margin: 20px 20px;
    max-width: 1500px;
    height: 800px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px solid white;
    background: rgba(100, 100, 100, 0.2);
    
    /* background: grey; */
    
    background-size: 500px 500px;
    padding: 20px 20px;
    
    /* box-shadow: 5px 5px 5px grey; */
   
}

#profile-display-container-profile:hover{
    box-shadow: 10px 10px 10px black;
}

.display-message h2{
    color: white;
    font-size: 30px;
    text-align: center;
}




#profile-display {
    /* margin-top: 1px; */
    /* margin-right: 80px; */
    /* border-radius: 4%; */
    
    border-bottom: 10px solid white;
    border-left: 10px solid white;
    border-top: 10px solid white;
    width: 700px;
    height: 700px;
    max-width: 700px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: lightgrey; */
    background: rgba(100, 100, 100, 0.7);
    color: white;
    

    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}




.profile-image-container img:hover{
    
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.2); */
    box-shadow: 10px 10px 10px black;

}

.display-profile-image-container img {
    background-image: contain;
    /* display: block; */
    height: 700px;
    width: 700px;
    border: 5px solid white;
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    border: 10px solid white;
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px grey;
    /* z-index: 4; */

    
    
}
.profile-display-info-logo {
    height: 60px;
    width: 60px;
    background-image: url(/static/media/pawprint.2542ccee.png);
    background-size: cover;
    margin-right: 15px;
}

.profile-display-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 690px;
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border-bottom: 4px solid white; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    /* box-shadow: 1px 10px 4px grey; */
    border-bottom: 4px solid grey;
    text-shadow: 1px 1px 1px lightgrey;
    
    
    /* width: 200px; */
}

.profile-display .profile-info {
    height: 300px;
    width: 350px;
    text-align: center;
    background: white;
    color: grey;
    

    font-size: 13px;
    /* border: 1px solid white; */
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    box-shadow: 5px 5px 5px grey;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.select-favorites{
    height: 200px;
    width: 500px;
    text-align: center;
    padding: 70px 10px;
}

#display-dropzone{
    height: 200px;
    width: 300px;
    border: 2.2px lightgray dashed;
    padding-top: 70px;
    text-align: center;
    justify-content: center;
}

.display-profile .display-name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

#profile-display .profile-info h3{
    font-size: 30px;
}

#display-add {
    margin-bottom:60px;
    background: url(/static/media/heartwithpaw.6ddcab37.png);
    background-size: cover;
    height: 100px;
    width: 100px;
}

#display-add:hover{
    -webkit-transform: scale(1.4);
    transform: scale(1.5);
}

#display-profile-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

@media screen and (max-width: 768px) and (min-width: 415px) {
    #profile-display-container-profile{
        flex-direction: column;
        max-width: 768px;
        height: 1500px;
    }

}

@media screen and (max-width: 414px) {
    #display-dropzone{
        height: 100px;
        width: 300px;
        padding-top: 30px;
    }
    #profile-display-container-profile{
        max-width: 414px;
        margin-top:100px;
    }

    #profile-display {
        max-width: 414px;
        /* height: 900px; */
        border: none;
    }
    .display-profile-image-container img {
        height: 414px;
        width: 414px;
        border: 2px solid white;
    }
    .display-profile-image-container  {
        padding-bottom: 100px;
    }


    .profile-display-name {
        max-width: 414px;
    }
    #profile-display-container-profile{
        flex-direction: column;
        padding: 0px 0px;
    }

    .footer {
        bottom: -150px;
    }

}
.title i {
    font-size: 40px;
    padding-left: 30px;
    
}

.mapped-friends{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 350px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    /* background: url('./../Home/resources/opacityprints.png'); */
    /* border-radius: 9%;x/ */
    box-shadow: 3px 3px 3px grey;

    overflow: scroll;
    margin-bottom: 30px;
}

.friends {
    /* background: linear-gradient( white, grey, white); */
    background: lightgrey;
    /* height: 100vh; */
    height: 1700px;
}

.mapped-current-friend{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 800px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;

    /* overflow: scroll; */
}

.no-friends{
    width: 100%;
    height: 1500px;
    max-width: 1500px;
    margin: 0 auto;
    background: url(/static/media/dutchie1.dabedc13.JPG);
    background-size: 100%;
    background-repeat: no-repeat;
}

.no-friends-link{
    font-size: 50px;
    text-decoration: none;
    color: white;
}

.no-friends-container{
    background: rgba(100, 100, 100, 0.7);
    padding: 20px 20px;

}

.click-on-friend{
    max-width: 1500px;
    width:100%;
    margin: 0 auto;
    text-align: center;
    /* background: url('./../resources/dutchie1.JPG'); */
    height: 1900px;
    background-size: cover;
}

.messages-container-at-friends{
    height: 200px;
    overflow: scroll;
}


@media screen and (max-width: 768px) {

    .title-container{
        width: 768px;
        background: lightgrey;
        border: 2px
    }
    .title{
        font-size: 30px;
        margin-top: 10px;
        background: grey;
        color: white;
        border: none;
        width: 100%;
        /* margin: 0 auto; */
    }

    .mapped-friends{
        border-radius: 0%;    
    }

    .mapped-current-friend{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 1300px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;

    /* overflow: scroll; */
    }

    

}

@media screen and (max-width: 414px) {
    .mapped-current-friend{
        max-width: 414px;
    }

    .mapped-friends{
        height: 300px;
    }

}



.friend-profile-container {
    width: 100%;
    max-height: 100%;
    
    max-width: 1500px;
    width: 100%;
    
    margin: 0 auto;
    height: 1000px;
    padding-right: 50px;
    padding-left: 50px;
    
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    
 
    /* border: 1px solid red; */
    background: grey;
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    background-size: 1900px 1900px;
    border: 1px solid grey;
    box-shadow: 3px 3px 3px grey;
    /* margin: 20px 20px; */
    border-radius: 1%;
    /* overflow: scroll; */
    display: flex;
    justify-content:center;
    align-items: center;
    /* flex-direction: column; */
   

}

.friend-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
}



.friend-profile {
    width: 1300px;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    background-repeat: no-repeat;
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}


.friend-profile-container img:hover{
    opacity: 2;
    /* border: 2px solid black; */
    -webkit-transform: scale(1.2);
    transform: scale(1.0);
    box-shadow: 5px 5px 5px black;
}

.friend-profile-container img {
    background-image: contain;
    /* display: block; */
    /* MARGIN-RIGHT: 5PX; */
    height: 700px;
    width: 700px;
    border: 10px solid white;
    /* border-bottom: 18px solid white; */
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    /* border: 8px solid rgb(248, 245, 245); */
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px black;

    
    
}
.friend-info-logo {
    height: 60px;
    width: 60px;
    background-image: url(/static/media/pawprint.2542ccee.png);
    background-size: cover;
    margin-right: 20px;
}

.friend-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
   
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* padding-top: 0px; */
    /* margin-top: 10px solid white; */
    /* border: 9px solid white; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border: 4px solid grey; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    box-shadow: 10px 6px 5px grey;
    text-shadow: 1px 1px 1px lightgrey;
    z-index: 2;
    /* width: 200px; */
}

.friend-profile .friend-info {
    height: 600px;
    /* padding-top:90px; */
    width: 700px;
    text-align: center;
    /* background: white; */
    color: grey;
    /* border-radius: 5%; */

    font-size: 13px;
    border-right: 10px solid white;
    border-bottom: 10px solid white;
    border-top: 5px solid white;
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    /* box-shadow: 5px 5px 5px grey; */
    box-shadow: 5px 5px 5px black;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
     
    
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

.friend-profile .info h3{
    font-size: 25px;
}



/* .friend-buttons button {
    background: url('./../resources/sadface.jpg');
    background-size: cover;
} */



.friend-delete i {
    font-size: 60px;
    /* background: lightgrey; */
    padding: 20px 20px;
    color: grey;
    margin-right: 20px;
}

.friend-delete i:hover {
    font-size: 50px;
    /* background: lightgrey; */
    padding: 20px 20px;
    color: black;
    -webkit-transform: scale(1.4);
    transform: scale(1.6);
}

.friend-message i {
    font-size: 60px;
    padding: 20px 20px;
    color: grey;
}

.friend-message i:hover {
    font-size: 50px;
    /* background: lightgrey; */
    padding: 20px 20px;
    color: black;
    -webkit-transform: scale(1.4);
    transform: scale(1.6);
}

.friend-buttons{
    display: flex;
    border: 2px solid white;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

}

#link-to-chatroom{
    /* background: lightgrey; */
    border: none;
}


@media screen and (max-width: 768px) {
.friend-profile-container {
    
    
    
    max-width: 768px;
    width: 100%;
    
    margin: 0 auto;
    height: 2000px;
    
    /* flex-wrap: wrap; */
    flex-direction: COLUMN;
    justify-content: flex-start;
    align-items: center;
 
    /* border: 1px solid red; */
    background: white;
    background-image: url(/static/media/opacityprints.3a76a89f.png);
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    /* background-size: 1900px 1900px; */
    border: 1px solid grey;
    box-shadow: 3px 3px 3px grey;
    margin: 20px 20px;
    border-radius: 1%;
    /* overflow: scroll; */
    display: flex;
    /* flex-direction: column; */
   

}
}

@media screen and (max-width: 414px) {
    .friend-profile-container {
        max-width: 414px;
        padding-right: 0px;
        padding-left: 0px;
    }
    .friend-profile {
        max-width: 414px;
    }
    .friend-profile-container img {
        height: 414px;
        width: 414px;
        border: 5px solid white;
    }
    .friend-name {
        width: 414px;
    }
    .friend-profile .friend-info {
        width: 414px;
    }
    .friend-info h3{
        font-size: 25px;
    }
    .friend-name h1{
        font-size: 45px;
    }
}

.chatrooms-container{
    width: 100%;
    HEIGHT: 1700px;
    
    /* background: url('./nala.JPG'); */
    /* MARGIN-TOP: 50PX; */

}

.chat-rooms-display{
    BORDER: 3PX SOLID WHITE;
    width: 100%;
    max-width: 1500px;
    HEIGHT: 100%;
    height: 1100px;
    margin: 0 auto;
    /* text-align: center; */
    /* margin-right: 100px; */
    background: url(/static/media/nala.4d8e46d2.JPG);
    BACKGROUND-SIZE: COVER;
}

.go button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    margin-right: 40px;
    z-index: 2;
}
.go button:hover{
    background: black;
    color: white;
}

.go {
    border: 3px solid white;
    
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 200px 200px; */
    margin: 50px 100px;
    background: rgba(100, 100, 100, 0.3);
    COLOR: WHITE;
    box-shadow: 5px 5px 5px black;
    
    
}

.go h3{
    FONT-SIZE: 35PX;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 414px) {
   .go{
    margin: 5px 5px;
    padding: 0px 10px;
   }

   .go h3{
    FONT-SIZE: 30PX;
    letter-spacing: 1px;
    }
    .chatrooms-container{
        /* margin-top: 600px; */
    }
}
.chatroom-container{
    width: 100%;
    height: 100%;
    
    
    border: 5px solid black;
}

.title-container-chatroom i {
    font-size: 40px;
    margin-right: 20px;
}

.chatroom{
    
    width: 100%;
    max-width: 1500px;
    height:1500px;
    
    margin: 0 auto;
    /* text-align: center; */
    border: 2px solid white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background: url(/static/media/pawprintsopacity.45a4b635.png);
    background-size: 550px auto;
    opacity: 1;
    
    
}

.title-container-chatroom{
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-container-chatroom button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 25px;
    border-radius: 5%;
    padding: 19px 15px;
    color: grey;
    height: 100%;
}

.chatroom p {
    /* padding: 5px 5px; */
    border: 2px solid black;
    border-radius: 15%;
    width: 100%;
    max-width: 300px;
    flex-wrap: wrap;
    /* background-color: white; */
    padding: 20px 20px;
    box-shadow: 5px 5px 5px grey;
    FONT-SIZE: 25PX;
}

.chatroom p:HOVER {
    /* padding: 5px 5px; */
    BACKGROUND: BLACK;
    COLOR: WHITE;
    BORDER: 4PX SOLID WHITE;
}

.messages-container{
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 30px;
    height: 900px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    /* padding: 50px 50px; */
}

.sender{
    justify-content: flex-start;
    /* color: black; */
    background: white;
    color: black;
}
.receiver{
    align-self: flex-end;
    background: grey;
    color:black;
}

.message-submit-form{
    width: 100%;
    height: 100px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: grey;
}

.message-input {
    font-size: 25px;
    padding: 8px 5px;
    margin-right: 10px;
    font-family: 'Fjalla One', sans-serif;
    
    /* letter-spacing: 1.5px; */

}

.message-submit-form button{
    font-family: 'Fjalla One', sans-serif;
    font-size: 25px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    /* margin-top: 20px; */
}

.message-submit-form button:hover{
    background: black;
    color: white;
}

.title-container-chatroom button:hover {
    background: black;
    color: white;
}

@media screen and (max-width: 414px) {
    .chatroom{
        max-width: 414px
    }

    .chatroom p {
        /* padding: 5px 5px; */
        border: 2px solid black;
        border-radius: 15%;
        width: 45%;
        max-width: 300px;
        flex-wrap: wrap;
        /* background-color: white; */
        padding: 10px 10px;
        box-shadow: 5px 5px 5px grey;
        FONT-SIZE: 20PX;
    }
}
header{  
    height: 150px;
    width: 100%;

    /* max-width: px; */
    /* margin: 0 auto; */
    position: -webkit-sticky;
    position: sticky;
    top: 1px;
    

    
    background: black;
    
 
    /* text-shadow: 4px 3px 3px ligtgrey; */
    color: white;


    /* position: fixed; */
    /* top: 0; */
    /* max-width: 1200px; */
    /* background: black; */
    /* background: black; */
    /* display: flex; */
    /* margin: 0 auto; */


}
.logo-container nav {
    /* width: 1150px; */
}
#next{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    
}

#next-test-nav-down{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}

#next-test-nav-down-register-login{
    display:flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}
#next button {
    font-size: 30px;
    width: 250px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: grey;
}
#nav-login-register nav{
    width: 1000px;
    
}

#next-test-nav-down button {
    font-size: 30px;
    width: 250px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}
#next button:hover {
    font-size: 30px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 30px;
    color: white;
    background: black;
}

#next-test{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    
    
}
#next-test button {
    font-size: 30px;
    width: 190px;
    font-family: 'Fjalla One', sans-serif;
    /* font-size: 30px; */
    /* border-radius: 5%; */
    padding: 5px 5px;
    color: white;
    background: black;
    border: none;
}
#next-test button:hover {
    font-size: 30px;
    /* font-family: 'Fjalla One', sans-serif; */
    /* font-size: 35px; */
    /* border-radius: 5%; */
    padding: 5px 5px;
    color: black;
    background: white;
    cursor: pointer;
}

.home-icon img{
    /* background-image: */
}
.nav-link-name{
    display: flex;
    align-items: center;
    justify-content: center;
    PADDING-LEFT: -200PX;
    width: 150px;
    margin-left: -70px;
}
.nav-link-name i {
    margin-left: 10px;
}

.nav-link-name:hover{
    color: white;
}



.nav{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    WIDTH: 100%;
   
}

.welcome-in{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1800px;
}




.logo-container {
    width: 100%;
    height: 150px;
    max-width: 1800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* justify-content: flex-start; */
    /* padding: 20px; */
    /* padding-left: 10px; */
    /* padding-RIGHT: 10px; */
}

/* .logo-container h1 {
    font-size: 70px;
} */

.nav-link, .nav-link-name {
    text-decoration: none;
    color: white;
    font-size: 25px;
   
    margin-right: 5px;
}

.nav h1{
    color: lightgrey;
    margin-right: 250px;
    
    
}


.nav-link:hover{
    color: lightgrey;
    
}

.logo {
    height: 130px;
    /* padding-bottom: 10px; */
    width: auto;
    /* padding-top: 10px; */
}

.header-nav-trial{
    display: flex;
    /* flex-direction: column; */
    width: 1500px;
    max-width: 1500px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    background: black;
    /* background: rgba(100, 100, 100, 0.7); */
    border-top: 15px solid white;
    border-radius: 15%;
    /* color: lightgrey; */
    height: 100px;
}

.header-nav-trial h1{
    font-size: 50px;
}

#next-test-nav-down {
    display: none;
    width: 100%;
    /* padding: 5px 5px; */
}

.nav-down-container{
    background: black;
    height: 100px;
    width: 100%;
    width: 100vw;
    

}

#next-test-nav-down-register-login{
    display: none
}






@media screen and (max-width: 768px) {
    .logo-container {
        justify-content: space-between;
    }

    .nav{
        width: 370px;
        justify-content: flex-end; 
        align-items: flex-end; 
    }
    
    .nav-link-name{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        PADDING-LEFT: 0PX;
        width: 0px;
        margin-left: 0px;
    }
    .nav-link-name i {
        margin-left: 10px;
    }


    .logo {
        margin-left: 40px;
    }

    .nav-link, .nav-link-name {
        text-decoration: none;
        color: white;
        font-size: 20px;
        /* margin-right: 25px; */
    }

    .nav h1{
        margin-right: 0px;
    }
    .logo-container {
        /* padding-top: 25px; */
        max-width: 768px;
        width: 100%;
        height: 90px;
        /* margin: 0 auto; */
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid white;
        padding-left: 0px;
        padding-RIGHT: 40px;
    }
    

    #next-test-nav-down {
        display: flex;
        position: absolute;
        
        
        top: 100px;
        height: 40px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: center;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
        
    }

    #next-test-nav-down button {
        font-size: 20px;
        width: 100%;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: lightgrey;
    }

    #next-test-nav-down button:hover{
        background: white;
        color: black;
        border: none;
    }
    

    header{
        max-width: 768px;
        height: 150px;
    }

    .logo {
        height: 70px;
        /* align-self: center; */
        /* padding-bottom: 10px; */
        width: auto;
        /* padding-top: 10px; */
    }

    #next-test{
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    #next-test button {
        font-size: 20px;
        border: 1px solid white;
    }

    #next-test-nav-down-register-login{
        display: flex;
        position: absolute;
        /* border-top: 1px solid white; */
        
        
        
        top: 100px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: space-between;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
    }

    #next-test-nav-down-register-login button {
        font-size: 20px;
        width: 100%;
        background: black;
        border: none;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: white;
    }
    #next-test-nav-down-register-login button:hover {
        background: white;
        color: black;
        height: 100%;
    }

    



    
}

@media screen and (max-width: 414px) {
    .nav{
        width: 200px;
        justify-content: center; 
        align-items: center; 
    }
    .logo-container {
        /* padding-top: 25px; */
        max-width: 414px;
        width: 100%;
        height: 90px;
        /* margin: 0 auto; */
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid white;
        padding-left: 0px;
        padding-RIGHT: -0px;
    }

    .nav-link-name{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        PADDING-LEFT: 0PX;
        width:90px;
        margin-left: 0px;
    }
    .nav-link-name i {
        margin-left: 10px;
    }
    

    #next-test-nav-down {
        display: flex;
        position: absolute;
        
        
        top: 100px;
        height: 40px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: center;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
        
    }

    #next-test-nav-down button {
        font-size: 20px;
        width: 100%;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: lightgrey;
    }

    #next-test-nav-down button:hover{
        background: white;
        color: black;
        border: none;
    }
    

    header{
        width: 414px;
        height: 150px;
    }

    .logo {
        height: 70px;
        /* align-self: center; */
        /* padding-bottom: 10px; */
        width: auto;
        /* padding-top: 10px; */
    }

    #next-test{
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    #next-test button {
        font-size: 20px;
        border: 1px solid white;
    }

    #next-test-nav-down-register-login{
        display: flex;
        position: absolute;
        /* border-top: 1px solid white; */
        
        
        
        top: 100px;
        width: 100%;
        /* bottom: 0px; */
        justify-content: space-between;
        align-items: center;
        /* background: red; */
        color: white;
        
        border: none;
        width: 100%;
        overflow: scroll;
        
        /* border: red; */
        z-index: 1;
    }

    #next-test-nav-down-register-login button {
        font-size: 20px;
        width: 100%;
        background: black;
        border: none;
        
        font-family: 'Fjalla One', sans-serif;
        /* font-size: 30px; */
        border-radius: 5%;
        padding: 10px 30px;
        color: white;
    }
    #next-test-nav-down-register-login button:hover {
        background: white;
        color: black;
        height: 100%;
    }

}

