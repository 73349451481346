


.friend-profile-container {
    width: 100%;
    max-height: 100%;
    
    max-width: 1500px;
    width: 100%;
    
    margin: 0 auto;
    height: 1000px;
    padding-right: 50px;
    padding-left: 50px;
    
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    
 
    /* border: 1px solid red; */
    background: grey;
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    background-size: 1900px 1900px;
    border: 1px solid grey;
    box-shadow: 3px 3px 3px grey;
    /* margin: 20px 20px; */
    border-radius: 1%;
    /* overflow: scroll; */
    display: flex;
    justify-content:center;
    align-items: center;
    /* flex-direction: column; */
   

}

.friend-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
}



.friend-profile {
    width: 1300px;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    background-repeat: no-repeat;
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}


.friend-profile-container img:hover{
    opacity: 2;
    /* border: 2px solid black; */
    -webkit-transform: scale(1.2);
    transform: scale(1.0);
    box-shadow: 5px 5px 5px black;
}

.friend-profile-container img {
    background-image: contain;
    /* display: block; */
    /* MARGIN-RIGHT: 5PX; */
    height: 700px;
    width: 700px;
    border: 10px solid white;
    /* border-bottom: 18px solid white; */
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    /* border: 8px solid rgb(248, 245, 245); */
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px black;

    
    
}
.friend-info-logo {
    height: 60px;
    width: 60px;
    background-image: url('./../resources/pawprint.png');
    background-size: cover;
    margin-right: 20px;
}

.friend-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
   
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* padding-top: 0px; */
    /* margin-top: 10px solid white; */
    /* border: 9px solid white; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border: 4px solid grey; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    box-shadow: 10px 6px 5px grey;
    text-shadow: 1px 1px 1px lightgrey;
    z-index: 2;
    /* width: 200px; */
}

.friend-profile .friend-info {
    height: 600px;
    /* padding-top:90px; */
    width: 700px;
    text-align: center;
    /* background: white; */
    color: grey;
    /* border-radius: 5%; */

    font-size: 13px;
    border-right: 10px solid white;
    border-bottom: 10px solid white;
    border-top: 5px solid white;
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    /* box-shadow: 5px 5px 5px grey; */
    box-shadow: 5px 5px 5px black;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
     
    
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

.friend-profile .info h3{
    font-size: 25px;
}



/* .friend-buttons button {
    background: url('./../resources/sadface.jpg');
    background-size: cover;
} */



.friend-delete i {
    font-size: 60px;
    /* background: lightgrey; */
    padding: 20px 20px;
    color: grey;
    margin-right: 20px;
}

.friend-delete i:hover {
    font-size: 50px;
    /* background: lightgrey; */
    padding: 20px 20px;
    color: black;
    -webkit-transform: scale(1.4);
    transform: scale(1.6);
}

.friend-message i {
    font-size: 60px;
    padding: 20px 20px;
    color: grey;
}

.friend-message i:hover {
    font-size: 50px;
    /* background: lightgrey; */
    padding: 20px 20px;
    color: black;
    -webkit-transform: scale(1.4);
    transform: scale(1.6);
}

.friend-buttons{
    display: flex;
    border: 2px solid white;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

}

#link-to-chatroom{
    /* background: lightgrey; */
    border: none;
}


@media screen and (max-width: 768px) {
.friend-profile-container {
    
    
    
    max-width: 768px;
    width: 100%;
    
    margin: 0 auto;
    height: 2000px;
    
    /* flex-wrap: wrap; */
    flex-direction: COLUMN;
    justify-content: flex-start;
    align-items: center;
 
    /* border: 1px solid red; */
    background: white;
    background-image: url('./../Home/resources/opacityprints.png');
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    /* background-size: 1900px 1900px; */
    border: 1px solid grey;
    box-shadow: 3px 3px 3px grey;
    margin: 20px 20px;
    border-radius: 1%;
    /* overflow: scroll; */
    display: flex;
    /* flex-direction: column; */
   

}
}

@media screen and (max-width: 414px) {
    .friend-profile-container {
        max-width: 414px;
        padding-right: 0px;
        padding-left: 0px;
    }
    .friend-profile {
        max-width: 414px;
    }
    .friend-profile-container img {
        height: 414px;
        width: 414px;
        border: 5px solid white;
    }
    .friend-name {
        width: 414px;
    }
    .friend-profile .friend-info {
        width: 414px;
    }
    .friend-info h3{
        font-size: 25px;
    }
    .friend-name h1{
        font-size: 45px;
    }
}
