

#profile-container-profile{
    /* padding-top: 20px; */
    margin: 20px 20px;
    max-width: 1500px;
    height: 800px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 3px solid black;
    background: grey;
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    padding: 20px 20px;
    box-shadow: 5px 5px 5px grey;
}

#profile-container-profile:hover{
    box-shadow: 10px 10px 10px black;
}





#profile {
    /* margin-top: 1px; */
    /* margin-right: 80px; */
    /* border-radius: 4%; */
    
    border-bottom: 10px solid white;
    border-left: 10px solid white;
    border-top: 10px solid white;
    width: 700px;
    height: 700px;
    max-width: 700px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: lightgrey;
    /* color: white; */
    

    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}




.profile-image-container img:hover{
    
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.2); */
    box-shadow: 10px 10px 10px black;

}

.profile-image-container img {
    /* display: block; */
    height: 700px;
    width: 700px;
    border: 5px solid white;
    
    /* border-radius: 5%; */
    background-image: contain;
 
    border: 10px solid white;
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px grey;
    /* z-index: 4; */

    
    
}
.info-logo {
    height: 60px;
    width: 60px;
    background-image: url('./../resources/pawprint.png');
    background-size: cover;
    margin-right: 15px;
}

.name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 690px;
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border-bottom: 4px solid white; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    /* box-shadow: 1px 10px 4px grey; */
    border-bottom: 4px solid grey;
    text-shadow: 1px 1px 1px lightgrey;
    
    
    /* width: 200px; */
}

.profile .info {
    height: 300px;
    width: 350px;
    text-align: center;
    background: white;
    color: grey;
    

    font-size: 13px;
    /* border: 1px solid white; */
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    box-shadow: 5px 5px 5px grey;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

#profile .info h3{
    font-size: 30px;
}

#add {
    margin-bottom:60px;
    background: url('./../resources/heartwithpaw.png');
    background-size: cover;
    height: 100px;
    width: 100px;
}

#add:hover{
    -webkit-transform: scale(1.4);
    transform: scale(1.5);
}

#profile-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

@media screen and (max-width: 768px) {
    #profile-container-profile{
        flex-direction: column;
        max-width: 768px;
        height: 1500px;
    }

}

@media screen and (max-width: 414px) {
    #profile {
        width: 100%;
        height: 500px;
        max-width: 414px;
        border-left: 0px;
        border: none;
        background: grey;
        color: black;
    }

    .name {
        /* max-width: 357px; */
        width: 100%;
        height: 80px;
    }

    .profile-image-container img {
        height: 414px;
        width: 414px;
    }

    #profile-container-profile{
        padding: 0px 0px;
    }

    #profile .info h3{
        font-size: 20px;
    }

    .name h1{
        font-size: 50px;
    }
}