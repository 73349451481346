.user-pet-profiles{
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}

.home-my-profile{
    background: lightgrey;
    padding-bottom: 100px;
    position: relative;
    height: 1700px;
}

.user-pet-profiles-display{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* flex-wrap: wrap; */
    
    height: 1200px;
    max-width: 1700px;
    width: 100%;
    margin: 0 auto;

    overflow: scroll;
}

.user-info{
    /* display: none; */
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    background: rgba(100, 100, 100, 0.7);
    /* border-top: 15px solid white; */
    /* border-radius: 15%; */
    color: lightgrey;
    height: 80px;
}

.user-info input{
    font-size: 30px;
    font-family: 'Fjalla One', sans-serif;
    padding: 5px 5px;
}

.user-info:hover{
    color: white;
    background: rgba(100, 100, 100, 0.9);
}


.user-info h2 {
    font-size: 30px;
}

.user-info h1 {
    font-size: 30px;
    /* color: grey; */
}

.title{
    font-size: 40px;
    border: 1px solid lightgrey;
    /* margin: 20px 20px; */
    padding: 10px 10px;
    width: 100%;
    text-align: center;
    /* border-radius: 7%; */
    /* BACKGROUND: BLACK; */
    COLOR: WHITE;
    background: rgba(100, 100, 100, 0.4);


}

.user-info button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    margin-right: 40px;
}

.user-pet-profiles{
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.user-pet-profiles button{
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    margin-right: 40px;
}

.user-pet-profiles button:hover{
    background: black;
    color: white;
}

.profile-buttons {
    
}

@media screen and (max-width: 768px) {
    .user-info{
        display: flex;
    /* flex-direction: column; */
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    margin-top: 20px;
    
    }
    .user-info h2 {
        font-size: 20px;
    }
    
    .user-info h1 {
        font-size: 20px;
        /* color: grey; */
    }

    .user-info button {
        font-family: 'Fjalla One', sans-serif;
        font-size: 10px;
        border-radius: 5%;
        padding: 5px 10px;
        color: grey;
        margin-right: 40px;
    }

    .user-pet-profiles-display{
        max-width: 768px;
        height: 1000px;
    }

}

@media screen and (max-width: 414px) {
    .user-pet-profiles h1 {
        font-size: 20px;
    }

    .user-pet-profiles{
        max-width: 414px;
    }
    .user-pet-profiles button{
        margin-right: 0px;
        
    }
    .user-pet-profiles-display{
        max-width: 414px;
        overflow: none;
        
    }

    .user-info{
        max-width: 414px;
    }

}
