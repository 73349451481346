.title i {
    font-size: 40px;
    padding-left: 30px;
    
}

.mapped-friends{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 350px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    /* background: url('./../Home/resources/opacityprints.png'); */
    /* border-radius: 9%;x/ */
    box-shadow: 3px 3px 3px grey;

    overflow: scroll;
    margin-bottom: 30px;
}

.friends {
    /* background: linear-gradient( white, grey, white); */
    background: lightgrey;
    /* height: 100vh; */
    height: 1700px;
}

.mapped-current-friend{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 800px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;

    /* overflow: scroll; */
}

.no-friends{
    width: 100%;
    height: 1500px;
    max-width: 1500px;
    margin: 0 auto;
    background: url('./../resources/dutchie1.JPG');
    background-size: 100%;
    background-repeat: no-repeat;
}

.no-friends-link{
    font-size: 50px;
    text-decoration: none;
    color: white;
}

.no-friends-container{
    background: rgba(100, 100, 100, 0.7);
    padding: 20px 20px;

}

.click-on-friend{
    max-width: 1500px;
    width:100%;
    margin: 0 auto;
    text-align: center;
    /* background: url('./../resources/dutchie1.JPG'); */
    height: 1900px;
    background-size: cover;
}

.messages-container-at-friends{
    height: 200px;
    overflow: scroll;
}


@media screen and (max-width: 768px) {

    .title-container{
        width: 768px;
        background: lightgrey;
        border: 2px
    }
    .title{
        font-size: 30px;
        margin-top: 10px;
        background: grey;
        color: white;
        border: none;
        width: 100%;
        /* margin: 0 auto; */
    }

    .mapped-friends{
        border-radius: 0%;    
    }

    .mapped-current-friend{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    
    height: 1300px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;

    /* overflow: scroll; */
    }

    

}

@media screen and (max-width: 414px) {
    .mapped-current-friend{
        max-width: 414px;
    }

    .mapped-friends{
        height: 300px;
    }

}