/* .friends h1{
        text-align: center;
    
        font-size: 45px;
        text-shadow: 4px 3px 3px black;
        color: white;

} */


.title-container{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}


.friends-profile-container {
    width: 100%;
    
    max-height: 100%;
    
    max-width: 350px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 800px;
    
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    justify-content: flex-start;
    align-items: center;
    
 
    /* border: 1px solid red; */
    /* background: white; */
    /* background-image: url('./../Home/resources/opacityprints.png'); */
    
    /* background-image: url('./../resources/pawprints.png'); */
    
    

    background-size: 1500px 1500px;
    /* border: 3px solid black; */
    /* box-shadow: 3px 3px 3px grey; */
    /* margin: 20px 20px; */
    /* border-radius: 1%; */
    /* overflow: scroll; */
    display: flex;
    /* flex-direction: column; */
   

}

.friends-image-container{
    padding-right: 40px;
}



.friends-profile {
    width: 1500px;
    max-width: 1500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('./../resources/pawprint.png');
    background-size: 30%;
    background-position: 0% -10%;
    opacity: 1%; */
    background-repeat: no-repeat;
    
    




    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}


.friends-profile-container img:hover{
    opacity: 2;
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.0); */
    /* box-shadow: 5px 5px 5px black; */
    -webkit-transform: scale(1.2);
    transform: scale(1.1);
}

.friends-profile-container img {
    background-image: contain;
    /* display: block; */
    height: 300px;
    width: 300px;
    border: 2px solid white;
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    border: 8px solid white;
    margin: 20px 20px;
    border-radius: 6%;
    box-shadow: 5px 5px 5px grey;

    
    
}
.friends-info-logo {
    height: 60px;
    width: 60px;
    background-image: url('./../resources/pawprint.png');
    background-size: cover;
    margin-right: 10px;
}

.friends-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    border: 4px solid grey;
    padding: 15px 15px;
    border-radius: 20%; 
    box-shadow: 10px 6px 5px grey;
    text-shadow: 1px 1px 1px lightgrey;
    z-index: 2;
    /* width: 200px; */
}

.friends-profile .info {
    height: 300px;
    width: 350px;
    text-align: center;
    background: white;
    color: grey;
    border-radius: 5%;

    font-size: 13px;
    border: 1px solid white;
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    box-shadow: 5px 5px 5px grey;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.profile .name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

.friends-profile .info h3{
    font-size: 25px;
}

.friends-buttons button {
    background: url('./../resources/heartwithpaw.png');
    background-size: cover;
}


@media screen and (max-width: 414px) {
    .friends-profile-container img {
        height: 250px;
        width: 250px;
        margin: 0px 5px;

    }

}