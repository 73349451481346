.registration-container-1{
    background-image: url('./../resources/babydutchie.JPG');
    background-size: 150%;
    background-repeat: no-repeat;
}

.registration-container{
    width: 100%;
    border: 2px solid white;
    background-image: url('./../resources/babydutchie.JPG');
    background-repeat: no-repeat;
    background-size: 160%;
    /* background-position: 90%; */
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    height: 1500px;
    text-shadow: 3px 2px 1px black;
    display: flex;
    justify-content: center;
    /* align-items: space-between; */
    padding-top: 100px;
}
.registration-form h1 {
    font-size: 65px;
    text-shadow: 4px 3px 3px black;
}

.registration-form p {
    font-size: 40px;
    text-align: center;
    align-self: center;
}

.welcome-icons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-icons i {
    font-size: 45px;
    margin-left: 20px;
    margin-right: 20px;
}

.registration-form button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
}

.registration-form button:hover {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    border-radius: 5%;
    padding: 10px 15px;
    color: white;
    background: black;
    cursor: pointer;
}

.registration-form{
    /* margin-top: 200px; */
    width: 100%;
    max-width: 1000px;
    height: 700px;
    /* margin: 0 auto; */
    
    /* margin: 0 auto; */
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-size: cover;
    /* height: 1000px; */
    background: rgba(100, 100, 100, 0.4);
    color: white;
    border-radius: 10%;
    border: 1px solid lightgrey;
    padding-bottom: 30px;
    /* box-shadow: 2px 5px 2px grey; */
}

.form {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    color: white;
    
}



.label-input{
    display: flex;
    flex-direction: column;
    
}
.label-input label {
    margin-right: 20px;
    /* margin-bottom: 15px; */
    /* align-self: center; */
}

.label-input input {
    margin-right: 20px;
    
    /* margin-bottom: 50px; */
    /* padding: 20px 20px; */
    /* border-radius: 7%; */
    font-family: 'Fjalla One', sans-serif;
    
}

.label-input input:hover {
    color: white;
    background: black;
    /* margin-right: 30px; */
    /* font-size: 40px; */
    /* margin-bottom: 50px; */
    /* padding: 20px 20px; */
    /* border-radius: 7%; */
    font-family: 'Fjalla One', sans-serif;
    
}

@media screen and (max-width: 768px) {
    .label-input {
        /* margin-bottom: px; */
        display: flex;
        flex-direction: column;
        margin-top: 45px;
    }

    .registration-form {
        /* flex-direction: column; */
    }

    .registration-container-1{
        height: 1700px;
    }

    .registration-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        background-size: cover;
        height: 100%;
        background: rgba(100, 100, 100, 0.4);
        color: white;
    
        width: 100%;
        max-width: 1500px;
        border-radius: 0%;
        
        
      
        border: 1px solid white;
        color: white;
    }

    .login-form h1 {
        text-align: center;
    }
    
}

@media screen and (max-width: 414px) {
    .registration-form{
        width: 100%;
        max-width: 414px;
        height: 100%;
        /* height: 200px; */
        border-radius: 0%;
        border: none;
        padding: 2px 2px;
        justify-content: flex-start;
        /* align-items: flex-start; */
        

    }

    .registration-container-1{
        height:1200px;
        overflow: scroll;
        padding-bottom: 150px;
    }
    .registration-container{
        padding-top: 0px;
        height: 100%;
        
        
        border: none;
        overflow: scroll;
    }

    .registration-form h1 {
    
        font-size: 30px;
        text-shadow: 2px 2px 2px black;
        text-align: center;
        
    }

    .label-input {
        margin-top: 0px;
    }

    .label-input input {
        /* margin-right: 10px; */
        font-size: 30px;
        margin-bottom: 0px;
       
        /* border-radius: 1%; */
        font-family: 'Fjalla One', sans-serif;
        /* text-shadow: 1px 1px 1px lightgrey; */
        padding: 10px 5px;
        margin-top: 0px;
        
        
    }
    form{
        height: 100%;
        overflow: scroll;
        /* padding-left: 20px; */
    }
    
}