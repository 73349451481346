

#profile-display-container-profile{
    /* padding-top: 20px; */
    margin: 20px 20px;
    max-width: 1500px;
    height: 800px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px solid white;
    background: rgba(100, 100, 100, 0.2);
    
    /* background: grey; */
    
    background-size: 500px 500px;
    padding: 20px 20px;
    
    /* box-shadow: 5px 5px 5px grey; */
   
}

#profile-display-container-profile:hover{
    box-shadow: 10px 10px 10px black;
}

.display-message h2{
    color: white;
    font-size: 30px;
    text-align: center;
}




#profile-display {
    /* margin-top: 1px; */
    /* margin-right: 80px; */
    /* border-radius: 4%; */
    
    border-bottom: 10px solid white;
    border-left: 10px solid white;
    border-top: 10px solid white;
    width: 700px;
    height: 700px;
    max-width: 700px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: lightgrey; */
    background: rgba(100, 100, 100, 0.7);
    color: white;
    

    /* padding-left: 20px; */
    /* flex-wrap: wrap; */
    /* background: rgba(100, 100, 100, 0.2); */
    
}




.profile-image-container img:hover{
    
    /* border: 2px solid black; */
    /* -webkit-transform: scale(1.2);
    transform: scale(1.2); */
    box-shadow: 10px 10px 10px black;

}

.display-profile-image-container img {
    background-image: contain;
    /* display: block; */
    height: 700px;
    width: 700px;
    border: 5px solid white;
    /* border-radius: 5%; */
    /* background-image: contain; */
 
    border: 10px solid white;
    /* margin: 20px 20px; */
    /* border-radius: 10%; */
    box-shadow: 5px 5px 5px grey;
    /* z-index: 4; */

    
    
}
.profile-display-info-logo {
    height: 60px;
    width: 60px;
    background-image: url('./../resources/pawprint.png');
    background-size: cover;
    margin-right: 15px;
}

.profile-display-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 690px;
    height: 100px;
    font-size: 30px;
    color: black;
    background: white;
    /* border: 1px solid black; */

    /* font-size: 55px; */
    text-align: center;
    background-color: white;
    /* border-bottom: 4px solid white; */
    /* padding: 15px 15px; */
    /* border-radius: 20%;  */
    /* box-shadow: 1px 10px 4px grey; */
    border-bottom: 4px solid grey;
    text-shadow: 1px 1px 1px lightgrey;
    
    
    /* width: 200px; */
}

.profile-display .profile-info {
    height: 300px;
    width: 350px;
    text-align: center;
    background: white;
    color: grey;
    

    font-size: 13px;
    /* border: 1px solid white; */
    background: rgb(218, 218, 218);
    /* border-radius: 10%; */
    color: black;
    text-shadow: 1px 1px 1px white;
    /* padding: 10px 10px; */
    box-shadow: 5px 5px 5px grey;
     /* background-image: url('./../resources/pawprint.png');
     background-repeat: no-repeat;
     background-size: 20%; */
    
}

.select-favorites{
    height: 200px;
    width: 500px;
    text-align: center;
    padding: 70px 10px;
}

#display-dropzone{
    height: 200px;
    width: 300px;
    border: 2.2px lightgray dashed;
    padding-top: 70px;
    text-align: center;
    justify-content: center;
}

.display-profile .display-name h1{
    /* width: 300px;
    font-size: 60px;
    color: black;
    background: white;
    border: 1px solid black;

    font-size: 55px;
    text-align: center;
    background-color: white;
    border: 5px solid grey;
    padding: 15px 15px;
    border-radius: 30%; 
    box-shadow: 10px 5px 5px grey;
    text-shadow: 1px 1px 1px lightgrey; */
}

#profile-display .profile-info h3{
    font-size: 30px;
}

#display-add {
    margin-bottom:60px;
    background: url('./../resources/heartwithpaw.png');
    background-size: cover;
    height: 100px;
    width: 100px;
}

#display-add:hover{
    -webkit-transform: scale(1.4);
    transform: scale(1.5);
}

#display-profile-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

@media screen and (max-width: 768px) and (min-width: 415px) {
    #profile-display-container-profile{
        flex-direction: column;
        max-width: 768px;
        height: 1500px;
    }

}

@media screen and (max-width: 414px) {
    #display-dropzone{
        height: 100px;
        width: 300px;
        padding-top: 30px;
    }
    #profile-display-container-profile{
        max-width: 414px;
        margin-top:100px;
    }

    #profile-display {
        max-width: 414px;
        /* height: 900px; */
        border: none;
    }
    .display-profile-image-container img {
        height: 414px;
        width: 414px;
        border: 2px solid white;
    }
    .display-profile-image-container  {
        padding-bottom: 100px;
    }


    .profile-display-name {
        max-width: 414px;
    }
    #profile-display-container-profile{
        flex-direction: column;
        padding: 0px 0px;
    }

    .footer {
        bottom: -150px;
    }

}