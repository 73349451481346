.add-pet-form-container{
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    /* display: flex;
    flex-direction: column; */
    /* background: url('./../resources/iggy2.JPG'); */
    background-repeat: no-repeat;
    background-size: cover;
    
    height: 1500px;
    /* background: url('./../resources/batmanbig.png'); */
    /* background: url('./../resources/batmanbig.png'); */
    /* background: url('./../resources/batmanbig.png');
    background-size: cover;
    background-repeat: no-repeat; */
}

.add-pet-form {
    background: rgba(100, 100, 100, 0.7);
    height: 300px;
    width: 100%;

    width: 100%;
    max-width: 1500px;
    height: 500px;
    
    /* margin: 0 auto; */
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: url('./../resources/batmanbig.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    
    
    /* height: 1000px; */
    
    

    border: 1px solid white;
    
    
}

.add-pet-form h1{
        font-size: 45px;
        text-shadow: 4px 3px 3px black;
        color: white;
    
}
.mapped-display-profile i{
    font-size: 30px;
    margin-left: 20px;
    align-self: center;
    justify-self: center;

}

.add-button-class{
    display: flex;
    justify-content: center;
    align-items: center;
}



.mapped-display-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 800px;
}

.mapped-display-profile button {
    font-family: 'Fjalla One', sans-serif;
    font-size: 25px;
    border-radius: 5%;
    padding: 10px 15px;
    color: grey;
    width: 1500px;

    
}
.mapped-display-profile button:HOVER {
    COLOR: WHITE;
    BACKGROUND: BLACK;

    
}
.add-pet-form input, textarea {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    padding: 5px 9px;
    color: hsl(0,0%,50%);
}

.selections {
    display: flex;
    flex-wrap: wrap;
    /* padding: 10px 10px; */
    justify-content: center;
    align-items: center;
    
}

.container-pet-form {
    /* background: url('./../resources/iggy.JPG'); */
    /* background-repeat: no-repeat; */
    background-size: 100%;
}

#dropzone{
    height: 300px;
    width: 300px;
    border: 2.2px lightgray dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.add-pet-link {
    text-decoration: none;
    color: black;
}
.add-pet-link:hover {
    color: white;
}

@media screen and (max-width: 414px) {
    .add-pet-form-container{
        max-width: 414px;
        display: flex;
        flex-direction: column;
    }

    .add-pet-form {
        max-width: 414px;
    }

    .add-pet-form h1{
        font-size: 30px;
    }

    .add-pet-form input, textarea {
        font-family: 'Fjalla One', sans-serif;
        font-size: 20px;
        padding: 5px 9px;
        color:black;
    }

    .select-favorites{
        text-align: center;
    }

    .selections textarea {
        width: 300px;
        height: 70px;
        padding: 20px 0px;
        text-align: center;
    }
   
   #dropzone{
       height: 50px;
       width: 50px;
       z-index: 1;
   }

   .add-pet-form {
       height: 700px;
   }

   #display-dropzone{
       height: 40px;
       width :40px;
   }
   .mapped-display-profile button {
    max-width: 414px;
    margin-bottom: 100px;

   }


   

  

 

   

    

    
}